import React from 'react'
import Chart from 'react-apexcharts'
import Skeleton from 'react-loading-skeleton'
import { A } from 'hookrouter'

import { DEFAULT_COLORS, CHART_INTERVAL, CHART_RANGE } from '../../constants'
import { numberFormat } from '../../utils'

export const CapitalFlow = ({
  theme,
  labels,
  data: originalData,
  i18n,
  notUseTitle,
  customTitle,
  interval,
  range,
  onChangeInterval,
  onChangeRange,
}) => {
  const data = [...originalData]
    .filter(d => d.set && d.set.length)
    .sort((a, b) => b.set[b.set.length - 1] - a.set[a.set.length - 1])
  if (data.length > 1) {
    const total = { label: i18n.Shared.CapitalFlow.total, set: [] }
    for (let i = 0; i < data[0].set.length; i++) {
      total.set[i] = data.reduce((res, current) => res += current.set[i], 0)
    }
    data.unshift(total)
  }

  const darkTheme = theme === 'dark'

  return (
    <div className="card card-bordered">
      <div className="card-inner">
        {!notUseTitle && (
          <div className="card-head ui-v2 mb-0">
            <div className="card-title">
              <h5 className="title">{customTitle || i18n.Shared.CapitalFlow.title}</h5>
            </div>
            <div className="card-tools shrink-0 d-none d-sm-block">
              <ul className="nav nav-switch-s2 nav-tabs bg-white mr-2">
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${interval === CHART_INTERVAL.ONE_DAY && 'active'}`}
                    onClick={() => onChangeInterval(CHART_INTERVAL.ONE_DAY)}
                  >1 D</A>
                </li>
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${interval === CHART_INTERVAL.ONE_WEEK && 'active'}`}
                    onClick={() => onChangeInterval(CHART_INTERVAL.ONE_WEEK)}
                  >7 D</A>
                </li>
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${interval === CHART_INTERVAL.ONE_MONTH && 'active'}`}
                    onClick={() => onChangeInterval(CHART_INTERVAL.ONE_MONTH)}
                  >30 D</A>
                </li>
              </ul>
              <ul className="nav nav-switch-s2 nav-tabs bg-white">
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${range === CHART_RANGE.ORIGIN && 'active'}`}
                    onClick={() => onChangeRange(CHART_RANGE.ORIGIN)}
                  >All</A>
                </li>
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${range === CHART_RANGE.Y5 && 'active'}`}
                    onClick={() => onChangeRange(CHART_RANGE.Y5)}
                  >5 Y</A>
                </li>
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${range === CHART_RANGE.Y1 && 'active'}`}
                    onClick={() => onChangeRange(CHART_RANGE.Y1)}
                  >1 Y</A>
                </li>
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${range === CHART_RANGE.M1 && 'active'}`}
                    onClick={() => onChangeRange(CHART_RANGE.M1)}
                  >1 M</A>
                </li>
              </ul>
            </div>
          </div>
        )}
        <div className="nk-ck3" style={{ marginBottom: '5px' }}>
          <Chart
            className="chart-account-summary chartjs-render-monitor"
            type="area"
            height={330}
            series={data.map((chart, index) => ({
              name: chart.label,
              data: chart.set.map((value, i) => [labels[i], value]),
              color: DEFAULT_COLORS[index],
            }))}
            options={{
              legend: {
                position: 'bottom',
                labels: {
                  colors: darkTheme ? '#fff' : [],
                },
              },
              responsive: [{
                breakpoint: 576,
                options: {
                  chart: {
                    height: 235,
                  },
                },
              }],
              chart: {
                type: 'area',
                stacked: false,
                zoom: {
                  type: 'x',
                  enabled: true,
                  autoScaleYaxis: true,
                },
                toolbar: {
                  tools: {
                    download: false,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true,
                  },
                  autoSelected: 'zoom',
                },
              },
              dataLabels: {
                enabled: false,
              },
              markers: {
                size: 0,
              },
              fill: {
                type: 'gradient',
                gradient: {
                  shadeIntensity: 1,
                  inverseColors: false,
                  opacityFrom: 0.5,
                  opacityTo: 0,
                  stops: [0, 90, 100],
                },
              },
              xaxis: {
                type: 'datetime',
                labels: {
                  format: 'yyyy-MM-dd',
                  style: {
                    colors: darkTheme ? '#fff' : [],
                  },
                },
              },
              yaxis: {
                labels: {
                  formatter: value => numberFormat(value, 0),
                  style: {
                    colors: darkTheme ? '#fff' : [],
                  },
                },
              },
              tooltip: {
                shared: false,
                y: {
                  formatter: val => numberFormat(val),
                },
              },
            }}
          />
        </div>
        <div className="mb-5 pb-3"></div>
      </div>
    </div>
  )
}

export const CapitalFlowLoader = () => (
  <div className="card card-bordered">
    <div className="card-inner">
      <Skeleton height={42} className="mb-2" />
      <Skeleton height={35} className="mb-3" />
      <Skeleton height={249} />
    </div>
  </div>
)
