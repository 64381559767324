import React from 'react'
import { A } from 'hookrouter'

export const MobileHeader = ({ onMobileSidebarClick }) => (
  <div className="nk-header nk-header-fixed nk-header-fluid is-light pl-0">
    <div className="container-fluid">
      <div className="nk-header-wrap">
        <div className="nk-header-brand d-xl-none">
          <A href="/" className="logo-link mobile-logo-link">
            <img className="logo-light logo-img mobile-sidebar-logo-img" src="/reagle.svg" srcSet="/reagle.svg 2x" alt="logo" />
            <img className="logo-dark logo-img mobile-sidebar-logo-img" src="/reagle.svg" srcSet="/reagle.svg 2x" alt="logo-dark" />
          </A>
        </div>
        <div className="nk-menu-trigger d-xl-none ml-auto mr-n1">
          <A href="" className="nk-nav-toggle nk-quick-nav-icon" onClick={onMobileSidebarClick}>
            <em className="icon ni ni-menu"></em>
          </A>
        </div>
      </div>
    </div>
  </div>
)
