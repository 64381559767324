import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { A } from 'hookrouter'

import { ASSET_CATEGORIES, SEARCH_RESPONSES_COUNT } from '../../constants'

const SearchComponent = ({ i18n }) => {
  const [query, setQuery] = useState('')
  const [category, setCategory] = useState(ASSET_CATEGORIES.STOCK)
  const [responses, setResponses] = useState([])

  const searchRequest = useCallback(async () => {
    const { data } = await axios.get('/assets/search', {
      params: { category, query, limit: SEARCH_RESPONSES_COUNT },
    })
    setResponses(data)
  }, [category, query])

  useEffect(() => {
    searchRequest()
  }, [searchRequest, category, query])

  const linkPrefix = `/assets/${category.toLowerCase()}/`

  return (
    <div className="nk-block">
      <div className="row g-gs justify-content-center">
        <div className="col-lg-6 mb-4 mt-4">
          <div className="card">
            <div className="card-search search-wrap active">
              <div className="search-content">
                <input
                  type="text"
                  className="form-control border-transparent form-focus-none"
                  placeholder={i18n.Search.searchPlaceholder}
                  onChange={e => setQuery(e.target.value)}
                />
                <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-gs">
        <div className="col-lg-12">
          <div className="card card-bordered card-full">
            <div className="card-inner">
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title"><span className="mr-2">{i18n.Search.title}</span></h6>
                </div>
                <div className="card-tools">
                  <ul className="card-tools-nav">
                    {[
                      ASSET_CATEGORIES.STOCK,
                      ASSET_CATEGORIES.ETF,
                      ASSET_CATEGORIES.CURRENCY,
                      ASSET_CATEGORIES.CRYPTOCURRENCY,
                    ].map(c => (
                      <li key={c} className={category === c ? 'active' : ''}>
                        <A href="" onClick={() => setCategory(c)}>
                          <span>{i18n.Search[c]}</span>
                        </A>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner p-0 border-top">
              <div className="nk-tb-list nk-tb-orders">
                <div className="nk-tb-item nk-tb-head">
                  <div className="nk-tb-col"><span>{i18n.Search.ticker}</span></div>
                  <div className="nk-tb-col"><span>{i18n.Search.name}</span></div>
                </div>
                {responses.map(r => (
                  <div className="nk-tb-item" key={r.ticker}>
                    <div className="nk-tb-col">
                      <span className="tb-lead"><A href={linkPrefix + r.ticker}>{r.ticker}</A></span>
                    </div>
                    <div className="nk-tb-col">
                      <span className="tb-lead">{r.longName}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {}

export const Search = connect(mapStateToProps, mapDispatchToProps)(SearchComponent)
