import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'

import { LightChart, LightChartLoader } from '../shared'
import { numberFormat, chartDates } from '../../utils'
import { ASSET_CATEGORIES, CHART_RANGE, CHART_INTERVAL } from '../../constants'

const AssetCurrencyComponent = ({ ticker, user, i18n, wide }) => {
  const [candles, setСandles] = useState(false)

  const [range, setRange] = useState(CHART_RANGE.Y1)
  const [interval, setInterval] = useState(CHART_INTERVAL.ONE_DAY)

  const candlesRequest = useCallback(async () => {
    const { data } = await axios.get(`/assets/${ASSET_CATEGORIES.CURRENCY}/${ticker}/candles`, {
      params: {
        ...chartDates(range),
        interval,
        currency: user.currentCurrency,
      },
    })
    setСandles(data)
  }, [range, interval, ticker, user.currentCurrency])

  useEffect(() => {
    candlesRequest()
  }, [candlesRequest])

  return !candles ? <AssetLoader wide={wide} /> : (
    <div className={`nk-block mx-auto ${!wide && 'wide-md'}`}>
      <div className="card card-bordered card-full">
        <div className="card-inner">
          <div className="card-title-group align-start">
            <div className="card-title">
              <h6>{ticker}</h6>
            </div>
          </div>
          <div className="nk-sale-data">
            <span className="amount">{numberFormat(candles.values[candles.values.length - 1].close)} {user.currentCurrency}</span>
          </div>
        </div>
      </div>
      <LightChart
        customTitle="Flow"
        theme={user.theme}
        i18n={i18n}
        labels={candles.dates.map(d => moment(d).format('YYYY-MM-DD'))}
        data={[{
          label: ticker,
          set: candles.values,
        }]}
        range={range}
        interval={interval}
        onChangeRange={setRange}
        onChangeInterval={setInterval}
      />
    </div>
  )
}

const AssetLoader = ({ wide }) => (
  <div className={`nk-block mx-auto ${!wide && 'wide-md'}`}>
    <div className="card card-bordered card-full">
      <div className="card-inner">
        <Skeleton height={43} className="mb-2" />
      </div>
    </div>
    <LightChartLoader />
  </div>
)

const mapStateToProps = state => ({
  i18n: state.i18n.dictionary,
  user: state.user,
})

const mapDispatchToProps = {}

export const AssetCurrency = connect(mapStateToProps, mapDispatchToProps)(AssetCurrencyComponent)
