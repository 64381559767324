import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { A, navigate } from 'hookrouter'

import { CURRENCIES, LANGUAGES } from '../../constants'
import { register, i18nInit } from '../../actions'

const RegistrationComponent = ({ user, register, i18n, i18nInit }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [language, setLanguage] = useState(LANGUAGES[0])
  const [firstCurrency, setFirstCurrency] = useState(CURRENCIES[0])
  const [secondCurrency, setSecondCurrency] = useState(CURRENCIES[1])
  const [thirdCurrency, setThirdCurrency] = useState(CURRENCIES[2])
  const [hide, setHide] = useState(true)

  useEffect(() => {
    if (user.isAuthenticated) {
      navigate('/')
    }
  }, [user])

  useEffect(() => {
    i18nInit()
  }, [i18nInit])

  const onSubmit = e => {
    e.preventDefault()
    register({
      email,
      password,
      language,
      currencies: [firstCurrency, secondCurrency, thirdCurrency],
    })
  }

  return i18n.isLoading ? <div></div> : (
    <div className="nk-app-root">
      <div className="nk-main ">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body wide-xs">
              <div className="brand-logo pb-4 text-center">
                <A href="/" className="logo-link">
                  <img className="logo-light logo-img logo-img-lg auth-logo" src="/reagle.svg" srcSet="/reagle.svg 2x" alt="logo" />
                  <img className="logo-dark logo-img logo-img-lg auth-logo" src="/reagle.svg" srcSet="/reagle.svg 2x" alt="logo-dark" />
                </A>
              </div>
              <div className="card card-bordered">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">{i18n.dictionary.Registration.title}</h4>
                      <div className="nk-block-des">
                        <p>{i18n.dictionary.Registration.make}</p>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <label className="form-label" htmlFor="email">{i18n.dictionary.Registration.email}</label>
                      <input
                        className="form-control form-control-lg"
                        id="email"
                        placeholder={i18n.dictionary.Registration.emailPlaceholder}
                        type="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        pattern=".{5,30}"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="password">{i18n.dictionary.Registration.password}</label>
                      <div className="form-control-wrap">
                        <A
                          href=""
                          onClick={() => setHide(!hide)}
                          className="form-icon form-icon-right passcode-switch"
                        >
                          <em className={`passcode-icon icon-show icon ni ${hide ? 'ni-eye' : 'ni-eye-off'}`}></em>
                        </A>
                        <input
                          className="form-control form-control-lg"
                          id="password"
                          placeholder={i18n.dictionary.Registration.passwordPlaceholder}
                          type={hide ? 'password' : 'text'}
                          name="password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          pattern=".{6,30}"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="password">{i18n.dictionary.Registration.currencies}</label>
                      <div className="form-control-wrap d-flex justify-content-between">
                        <div className="form-control-select">
                          <select value={firstCurrency} onChange={e => setFirstCurrency(e.target.value)} className="form-control mr-4" id="main" data-ui="sm" tabIndex="-1" aria-hidden="true">
                            {CURRENCIES.map(c => (
                              <option key={c} value={c}>{c}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-control-select">
                          <select value={secondCurrency} onChange={e => setSecondCurrency(e.target.value)} className="form-control mr-4" id="main" data-ui="sm" tabIndex="-1" aria-hidden="true">
                            {CURRENCIES.map(c => (
                              <option key={c} value={c}>{c}</option>
                            ))}
                          </select>
                        </div>
                        <div className="form-control-select">
                          <select value={thirdCurrency} onChange={e => setThirdCurrency(e.target.value)} className="form-control mr-4" id="main" data-ui="sm" tabIndex="-1" aria-hidden="true">
                            {CURRENCIES.map(c => (
                              <option key={c} value={c}>{c}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="form-label" htmlFor="password">{i18n.dictionary.Registration.language}</label>
                      <div className="form-control-wrap">
                        <div className="form-control-select">
                          <select value={language} onChange={e => setLanguage(e.target.value)} className="form-control" id="main" data-ui="sm" tabIndex="-1" aria-hidden="true">
                            {LANGUAGES.map(l => (
                              <option key={l} value={l}>{l.toUpperCase()}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-group">
                      <div className="custom-control custom-control-xs custom-checkbox">
                        <input type="checkbox" className="custom-control-input" id="checkbox" />
                        <label className="custom-control-label" htmlFor="checkbox">I agree to Reagle <a href="/">Privacy Policy</a> &amp; <a href="/"> Terms.</a></label>
                      </div>
                    </div> */}
                    <div className="form-group">
                      <button className="btn btn-lg btn-primary btn-block">{i18n.dictionary.Registration.title}</button>
                    </div>
                  </form>
                  <div className="form-note-s2 text-center pt-4">{i18n.dictionary.Registration.already} <A href="/login"><strong>{i18n.dictionary.Registration.signIn}</strong></A>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-footer nk-auth-footer-full">
              <div className="container wide-lg">
                <div className="row g-3">
                  <div className="col-lg-6 order-lg-last">
                    <ul className="nav nav-sm justify-content-center justify-content-lg-end">
                      <li className="nav-item dropup">
                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right"></div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-12">
                    <div className="nk-block-content text-center text-lg">
                      <p className="text-soft">&copy; {i18n.dictionary.Registration.copy}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({ user: state.user, i18n: state.i18n })

const mapDispatchToProps = {
  register,
  i18nInit,
}

export const Registration = connect(mapStateToProps, mapDispatchToProps)(RegistrationComponent)
