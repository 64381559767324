import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from 'hookrouter'

import { i18nInit, logout, setTheme } from '../../actions'
import { MobileFooter } from './mobile-footer'
import { MobileHeader } from './mobile-header'
import { Sidebar } from './sidebar'

const LayoutComponent = ({ children, user, i18nInit, i18n, logout, setTheme }) => {
  const [showSidebar, setShowSidebar] = useState(false)
  const [actions] = useState({
    closeMenu(e) {
      if (!e.target.classList.contains('not-close-sidebar')) {
        setShowSidebar(false)
      }
    },
  })

  const darkTheme = user.theme === 'dark'

  if (!user.isAuthenticated) {
    navigate('/login')
  }

  useEffect(() => {
    i18nInit(user.user.language)
  }, [i18nInit, user.user.language])

  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    if (darkTheme) {
      body.classList.add('dark-mode')
      setTheme('dark')
    } else {
      body.classList.remove('dark-mode')
      setTheme('light')
    }
  }, [darkTheme, setTheme])

  useEffect(() => {
    if (showSidebar) {
      document.addEventListener('click', actions.closeMenu)
    } else {
      document.removeEventListener('click', actions.closeMenu)
    }
  }, [showSidebar, actions])

  return i18n.isLoading ? <div></div> : (
    <div className="nk-app-root">
      <div className="nk-main">
        <Sidebar
          isOpenSidebar={showSidebar}
          user={user}
          isDarkTheme={darkTheme}
          onChangeTheme={() => setTheme(darkTheme ? 'light' : 'dark')}
          i18n={i18n.dictionary}
          logout={logout}
        />
        <div className="nk-wrap">
          <MobileHeader onMobileSidebarClick={() => setShowSidebar(!showSidebar)} />
          <div className="nk-content mt-3">
            <div className="container-fluid">
              <div className="nk-content-body">
                {children}
              </div>
            </div>
          </div>
          <MobileFooter />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  i18n: state.i18n,
})

const mapDispatchToProps = {
  i18nInit,
  logout,
  setTheme,
}

export const Layout = connect(mapStateToProps, mapDispatchToProps)(LayoutComponent)
