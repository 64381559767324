import React, { useState, useEffect } from 'react'

export const DropdownMenu = ({ btn, content }) => {
  const [showMenu, setShowMenu] = useState(false)
  const [actions] = useState({
    closeMenu() {
      setShowMenu(false)
    },
  })

  useEffect(() => {
    if (showMenu) {
      document.addEventListener('click', actions.closeMenu)
    } else {
      document.removeEventListener('click', actions.closeMenu)
    }
    return () => {
      document.removeEventListener('click', actions.closeMenu)
    }
  }, [showMenu, actions])

  return (
    <>
      {React.cloneElement(btn, {
        onClick() {
          setShowMenu(true)
        },
      })}
      {showMenu ? content : null}
    </>
  )
}
