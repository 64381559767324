export * from './i18n'

const COLORS = [
  '#827cff',
  '#bfa2ff',
  '#ff9ed0',
  '#9999FF',
  '#CC99CC',
  '#6600FF',
  '#CC6699',
  '#9966FF',
  '#339999',
  '#9999CC',
  '#FF00FF',
  '#00CC99',
]

export const DEFAULT_COLORS = [ // 96 colors
  ...COLORS,
  ...COLORS,
  ...COLORS,
  ...COLORS,
  ...COLORS,
  ...COLORS,
  ...COLORS,
  ...COLORS,
]

export const SEARCH_RESPONSES_COUNT = 20

export const CURRENCIES = [
  'USD',
  'EUR',
  'JPY',
  'CNY',
  'GBP',
  'CHF',
  'RUB',
  'CAD',
  'BGN',
  'CZK',
  'DKK',
  'HUF',
  'PLN',
  'RON',
  'SEK',
  'ISK',
  'NOK',
  'HRK',
  'TRY',
  'AUD',
  'BRL',
  'HKD',
  'IDR',
  'ILS',
  'INR',
  'KRW',
  'MXN',
  'MYR',
  'NZD',
  'PHP',
  'SGD',
  'THB',
  'ZAR',
]

export const LANGUAGES = [
  'en',
  'ru',
]

export const DEFAULT_LANGUAGE = 'en'

export const ASSET_CATEGORIES = {
  STOCK: 'stock',
  ETF: 'etf',
  CURRENCY: 'currency',
  CRYPTOCURRENCY: 'cryptocurrency',
  OTHER: 'other',
}

export const TRANSACTION_TYPES = {
  BUY: 'buy',
  SELL: 'sell',
  DIVIDEND: 'dividend',
  TAX: 'tax',
}

export const CHART_INTERVAL = {
  ONE_DAY: '1day',
  ONE_WEEK: '1week',
  ONE_MONTH: '1month',
  THREE_MONTH: '3month',
  ONE_YEAR: '1year',
}

export const CHART_RANGE = {
  ORIGIN: 'origin',
  Y1: 12,
  Y5: 12 * 5,
  M1: 1,
}

export const MIN_DATE = new Date(1818, 4, 5)
