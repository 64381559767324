import React from 'react'
import { A } from 'hookrouter'
import { connect } from 'react-redux'

export const NotFoundComponent = ({ linkPath, linkText, i18n }) => (
  <div className="nk-content ">
    <div className="nk-block nk-block-middle wide-md mx-auto">
      <div className="nk-block-content nk-error-ld text-center">
        <img className="nk-error-gfx" src="/images/404.svg" alt="" />
        <div className="wide-xs mx-auto">
          <h3 className="nk-error-title">{i18n.NotFound.title}</h3>
          <p className="nk-error-text">
            {i18n.NotFound.massage}
          </p>
          <A href={linkPath} className="btn btn-lg btn-primary mt-2">
            {linkText}
          </A>
        </div>
      </div>
    </div>
  </div>
)

const mapStateToProps = state => ({
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {}

export const NotFound = connect(mapStateToProps, mapDispatchToProps)(NotFoundComponent)
