import React from 'react'
import { useRoutes, useRedirect } from 'hookrouter'
import { Provider } from 'react-redux'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

import { setAuthToken } from './utils'
import { setCurrentUser, logout, setCurrentTheme } from './actions'
import { store } from './store'
import {
  AssetCryptocurrency,
  AssetCurrency,
  AssetETF,
  AssetStock,
  CreatePortfolio,
  CreateTransaction,
  Dashboard,
  Layout,
  Login,
  NotFoundPrivate,
  NotFoundPublic,
  Portfolios,
  Registration,
  ResetPasswordFinish,
  ResetPassword,
  Search,
  PersonalSettings,
  SecuritySettings,
  Transactions,
  UpdateTransaction,
  Verification,
  Watchlist,
} from './components'

axios.defaults.timeout = 1000 * 60 * 2
axios.defaults.baseURL = process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_PROD !== 'true' ? 'http://localhost:8080' : 'https://api.reagle.net'

if (localStorage.access_token) {
  const { access_token } = localStorage
  setAuthToken(access_token)
  const decoded = jwtDecode(access_token)
  const currentTime = Date.now() / 1000
  if (decoded.exp < currentTime) {
    store.dispatch(logout())
  } else {
    store.dispatch(setCurrentUser(decoded))
    store.dispatch(setCurrentTheme(localStorage.getItem('theme') || 'dark'))
  }
}

const routes = {
  '/assets/cryptocurrency/:ticker': ({ ticker }) => <Layout><AssetCryptocurrency ticker={ticker} /></Layout>,
  '/assets/currency/:ticker': ({ ticker }) => <Layout><AssetCurrency ticker={ticker} /></Layout>,
  '/assets/etf/:ticker': ({ ticker }) => <Layout><AssetETF ticker={ticker} /></Layout>,
  '/assets/stock/:ticker': ({ ticker }) => <Layout><AssetStock ticker={ticker} /></Layout>,
  '/create-portfolio': () => <Layout><CreatePortfolio /></Layout>,
  '/create-transaction': () => <Layout><CreateTransaction /></Layout>,
  '/dashboard': () => <Layout><Dashboard /></Layout>,
  '/login': () => <Login />,
  '/portfolios': () => <Layout><Portfolios /></Layout>,
  '/registration': () => <Registration />,
  '/reset-password/finish/:resetKey': ({ resetKey }) => <ResetPasswordFinish resetKey={resetKey} />,
  '/reset-password/init': () => <ResetPassword />,
  '/search': () => <Layout><Search /></Layout>,
  '/settings': () => <Layout><PersonalSettings /></Layout>,
  '/settings/security': () => <Layout><SecuritySettings /></Layout>,
  '/transactions': () => <Layout><Transactions /></Layout>,
  '/update-transaction/:id': ({ id }) => <Layout><UpdateTransaction transactionId={id} /></Layout>,
  '/verification/:verifyKey': ({ verifyKey }) => <Verification verifyKey={verifyKey} />,
  '/watchlist': () => <Layout><Watchlist /></Layout>,
}

function App() {
  useRedirect('/', '/dashboard')
  const routeResult = useRoutes(routes) || (store.getState().user.isAuthenticated ?
    <Layout><NotFoundPrivate /></Layout> : <NotFoundPublic />)
  return (
    <Provider store={store}>
      {routeResult}
    </Provider>
  )
}

export default App
