import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import { navigate } from 'hookrouter'

import { CHART_INTERVAL } from '../../constants'
import { PortfolioList, PortfolioListLoader } from '../shared'

const PortfoliosPage = ({ user }) => {
  const [portfolios, setPortfolios] = useState(false)
  const [portfolioCharts, setPortfolioCharts] = useState(false)

  const portfoliosRequest = useCallback(async () => {
    const { data } = await axios.get('/portfolio/details', {
      params: { currency: user.currentCurrency },
    })
    setPortfolios(data)
  }, [user.currentCurrency])

  const portfolioChartsRequest = useCallback(async () => {
    const { data } = await axios.get('/portfolio/details/candles', {
      params: {
        from: moment().subtract(1, 'year').toDate(),
        to: moment().toDate(),
        interval: CHART_INTERVAL.ONE_MONTH,
        currency: user.currentCurrency,
      },
    })
    setPortfolioCharts(data)
  }, [user.currentCurrency])

  useEffect(() => {
    portfoliosRequest()
    portfolioChartsRequest()
  }, [portfoliosRequest, portfolioChartsRequest, user.currentCurrency])

  const removePortfolio = async id => {
    await axios.delete(`/portfolio/${id}`)
    setPortfolios(portfolios.filter(p => p.id !== id))
  }

  const updatePortfolio = async portfolio => {
    const { data } = await axios.put('/portfolio', portfolio)
    navigate(`/dashboard?portfolios=${data.id}`)
  }

  return (
    <div className="nk-block">
      <div className="row g-gs">
        <div className="col-lg-12">
          {!portfolios || !portfolioCharts ?
            <PortfolioListLoader /> :
            <PortfolioList
              portfolios={portfolios}
              portfolioCharts={portfolioCharts}
              removePortfolio={removePortfolio}
              updatePortfolio={updatePortfolio}
            />}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user,
})

const mapDispatchToProps = {}

export const Portfolios = connect(mapStateToProps, mapDispatchToProps)(PortfoliosPage)
