import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { A, navigate } from 'hookrouter'

import { login, i18nInit } from '../../actions'

const LoginComponent = ({ user, login, i18n, i18nInit }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hide, setHide] = useState(true)

  useEffect(() => {
    if (user.isAuthenticated) {
      navigate('/')
    }
  }, [user])

  useEffect(() => {
    i18nInit()
  }, [i18nInit])

  const onSubmit = e => {
    e.preventDefault()
    login({ email, password })
  }

  return i18n.isLoading ? <div></div> : (
    <div className="nk-app-root">
      <div className="nk-main">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <A href="/" className="logo-link">
                  <img
                    className="logo-light logo-img logo-img-lg auth-logo"
                    src="/reagle.svg"
                    srcSet="/reagle.svg 2x"
                    alt="logo"
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg auth-logo"
                    src="/reagle.svg"
                    srcSet="/reagle.svg 2x"
                    alt="logo-dark"
                  />
                </A>
              </div>
              <div className="card card-bordered">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">{i18n.dictionary.Login.signIn}</h4>
                      <div className="nk-block-des">
                        <p>{i18n.dictionary.Login.access}</p>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">
                          {i18n.dictionary.Login.email}
                        </label>
                      </div>
                      <input
                        className="form-control form-control-lg"
                        id="default-01"
                        placeholder={i18n.dictionary.Login.emailPlaceholder}
                        type="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        pattern=".{5,30}"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="password">
                          {i18n.dictionary.Login.password}
                        </label>
                        <A
                          className="link link-primary link-sm"
                          href="/reset-password/init"
                        >
                          {i18n.dictionary.Login.forgot}
                        </A>
                      </div>
                      <div className="form-control-wrap">
                        <A
                          href=""
                          onClick={() => setHide(!hide)}
                          className="form-icon form-icon-right passcode-switch"
                        >
                          <em className={`"passcode-icon icon-show icon ni ${hide ? 'ni-eye' : 'ni-eye-off'}`}></em>
                        </A>
                        <input
                          className={`form-control form-control-lg is-${hide ? 'hidden' : 'shown'}`}
                          id="password"
                          placeholder={i18n.dictionary.Login.passwordPlaceholder}
                          type={hide ? 'password' : 'text'}
                          name="password"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          pattern=".{6,30}"
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-lg btn-primary btn-block">
                        {i18n.dictionary.Login.signIn}
                      </button>
                    </div>
                  </form>
                  <div className="form-note-s2 text-center pt-4">
                    {' '}
                    {i18n.dictionary.Login.new}{' '}
                    <A href="/registration">
                      {i18n.dictionary.Login.create}
                    </A>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-footer nk-auth-footer-full">
              <div className="container wide-lg">
                <div className="row g-3">
                  <div className="col-lg-12">
                    <div className="nk-block-content text-center text-lg">
                      <p className="text-soft">
                        &copy; {i18n.dictionary.Login.copy}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  i18n: state.i18n,
})

const mapDispatchToProps = {
  login,
  i18nInit,
}

export const Login = connect(mapStateToProps, mapDispatchToProps)(LoginComponent)
