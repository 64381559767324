import axios from 'axios'
import jwtDecode from 'jwt-decode'
import Swal from 'sweetalert2'
import { navigate } from 'hookrouter'

import { SET_CURRENT_USER, SET_CURRENT_CURRENCY, SET_CURRENT_THEME } from './types'
import { setAuthToken } from '../utils'

export const register = credentials => async dispatch => {
  const { data: { token } } = await axios.post('/registration', credentials)
  authUser(token, dispatch)
}

export const login = credentials => async dispatch => {
  const { data: { token } } = await axios.post('/login', credentials)
  authUser(token, dispatch)
}

const authUser = (token, dispatch) => {
  localStorage.setItem('access_token', token)
  setAuthToken(token)
  const decoded = jwtDecode(token)
  dispatch(setCurrentUser(decoded))
}

export const logout = () => dispatch => {
  localStorage.removeItem('access_token')
  setAuthToken(false)
  window.location.href = '/login'
  // dispatch(setCurrentUser({}))
}

export const updateUser = user => async dispatch => {
  const { data: { token } } = await axios.put('/user', user)
  localStorage.setItem('access_token', token)
  setAuthToken(token)
  const decoded = jwtDecode(token)
  dispatch(setCurrentUser(decoded))
}

export const resetPassword = (resetKey, password, i18n) => async dispatch => {
  try {
    const { data: { token } } = await axios.post('/reset-password/finish', {
      key: resetKey,
      password,
    })
    authUser(token, dispatch)
    navigate('/')
  } catch {
    await Swal.fire({
      title: i18n.ResetPasswordFinish.modalTitleError,
      text: i18n.ResetPasswordFinish.modalTitleTextError,
      icon: 'error',
    })
    navigate('/reset-password/init')
  }
}

export const setCurrentCurrency = currency => dispatch => {
  dispatch({
    type: SET_CURRENT_CURRENCY,
    payload: currency,
  })
}

export const setCurrentUser = user => ({
  type: SET_CURRENT_USER,
  payload: user,
})

export const verificationEmail = (verifyKey, i18n) => async dispatch => {
  try {
    const { data: { token } } = await axios.get(`verify/${verifyKey}`)
    authUser(token, dispatch)
    await Swal.fire({
      title: i18n.Verify.modalTitleSuccess,
      html: i18n.Verify.html,
      timer: 2000,
      timerProgressBar: true,
    })
    navigate('/')
  } catch {
    await Swal.fire({
      title: i18n.Verify.modalTitleError,
      text: i18n.Verify.text,
      icon: 'error',
    })
    navigate('/registration')
  }
}

export const setTheme = theme => dispatch => {
  localStorage.setItem('theme', theme)
  dispatch(setCurrentTheme(theme))
}

export const setCurrentTheme = theme => ({
  type: SET_CURRENT_THEME,
  payload: theme,
})
