import { SET_LANGUAGE } from './types'
import { LANGUAGES, DEFAULT_LANGUAGE, I18N } from '../constants'

const currentLanguage = () => {
  const userLang = navigator.language.substring(0, 2)
  return LANGUAGES.includes(userLang) ? userLang : DEFAULT_LANGUAGE
}

export const i18nInit = language => async dispatch => {
  language = (language || currentLanguage()).toUpperCase()
  dispatch({
    type: SET_LANGUAGE,
    payload: I18N[language] || I18N[DEFAULT_LANGUAGE.toUpperCase()],
  })
}
