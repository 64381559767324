import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { A } from 'hookrouter'

import { i18nInit, resetPassword } from '../../actions'

const ResetPasswordFinishComponent = ({ i18n, i18nInit, resetKey, resetPassword }) => {
  const [password, setPassword] = useState('')
  const [hide, setHide] = useState(true)

  useEffect(() => {
    i18nInit()
  }, [i18nInit])

  const onSubmit = async e => {
    e.preventDefault()
    resetPassword(resetKey, password, i18n.dictionary)
  }

  return i18n.isLoading ? <div></div> : (
    <div className="nk-app-root">
      <div className="nk-main">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body wide-xs">
              <div className="brand-logo pb-4 text-center">
                <A href="/" className="logo-link">
                  <img
                    className="logo-light logo-img logo-img-lg auth-logo"
                    src="/reagle.svg"
                    srcSet="/reagle.svg 2x"
                    alt="logo"
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg auth-logo"
                    src="/reagle.svg"
                    srcSet="/reagle.svg 2x"
                    alt="logo-dark"
                  />
                </A>
              </div>
              <div className="card card-bordered">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h5 className="nk-block-title">{i18n.dictionary.ResetPasswordFinish.title}</h5>
                      <div className="nk-block-des">
                        <p>{i18n.dictionary.ResetPasswordFinish.titleText}</p>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <A
                        href=""
                        onClick={() => setHide(!hide)}
                        className="form-icon form-icon-right passcode-switch"
                      >
                        <em className={`"passcode-icon icon-show icon ni ${hide ? 'ni-eye' : 'ni-eye-off'}`}></em>
                      </A>
                      <input
                        className={`form-control form-control-lg is-${hide ? 'hidden' : 'shown'}`}
                        id="password"
                        placeholder={i18n.dictionary.ResetPasswordFinish.passwordPlaceholder}
                        type={hide ? 'password' : 'text'}
                        name="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        pattern=".{6,30}"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-lg btn-primary btn-block">{i18n.dictionary.ResetPasswordFinish.send}</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="nk-footer nk-auth-footer-full">
              <div className="container wide-lg">
                <div className="row g-3">
                  <div className="col-lg-12">
                    <div className="nk-block-content text-center text-lg">
                      <p className="text-soft">
                        &copy; {i18n.dictionary.ResetPasswordFinish.copy}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  i18n: state.i18n,
})

const mapDispatchToProps = {
  resetPassword,
  i18nInit,
}

export const ResetPasswordFinish = connect(
  mapStateToProps, 
  mapDispatchToProps,
)(ResetPasswordFinishComponent)
