import React from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import axios from 'axios'

import { updateUser } from '../../actions'
import { SettingsLayout } from './layout'

export const SecuritySettingsComponent = ({ i18n, user, updateUser }) => {
  const onClickChangePassword = () => Swal.fire({
    title: i18n.SecuritySettings.changePassword,
    input: 'text',
    inputLabel: i18n.SecuritySettings.placeholderPassword,
    showCancelButton: true,
    inputValidator: value => {
      if (!value || value.length < 6 || value.length > 30) {
        return 'Error.'
      }
    },
  }).then(result => {
    if (result.isConfirmed) {
      save({ password: result.value })
    }
  })

  const onClickVerificationRetry = async () => {
    const askModal = await Swal.fire({
      title: i18n.SecuritySettings.modalActivateTittle,
      showCancelButton: true,
      confirmButtonText: i18n.SecuritySettings.modalActivateSend,
      cencelButtonText: i18n.SecuritySettings.modalActivateCencel,
    })
    if (askModal.isConfirmed) {
      try {
        await axios.get('/verify/retry')
        await Swal.fire({
          icon: 'success',
          text: i18n.ResetPasswordFinish.reAuthenticationTextSuccess,
        })
      } catch {
        await Swal.fire({
          icon: 'error',
          text: i18n.ResetPasswordFinish.reAuthenticationTextError,
        })
      }
    }
  } 

  const onClickChangeEmail = () => Swal.fire({
    title: i18n.SecuritySettings.changeEmail,
    input: 'email',
    inputLabel: i18n.SecuritySettings.placeholderEmail,
    showCancelButton: true,
    validationMessage: 'Error.',
  }).then(result => {
    if (result.isConfirmed) {
      save({ email: result.value })
    }
  })

  const save = data => updateUser({
    ...user.user,
    ...data,
  })

  const styleCheck = {
    marginRight: '32px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
  }

  return (
    <SettingsLayout>
      <div className="card-inner card-inner-lg">
        <div className="nk-block-head nk-block-head-lg">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h4 className="nk-block-title">{i18n.SecuritySettings.title}</h4>
              <div className="nk-block-des">
                <p>{i18n.SecuritySettings.these}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-inner-group">
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>{i18n.SecuritySettings.password}</h6>
                    <p>{i18n.SecuritySettings.set}</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <button type="button" className="btn btn-primary" onClick={onClickChangePassword}>{i18n.SecuritySettings.change}</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>{i18n.SecuritySettings.email}</h6>
                    <p>{i18n.SecuritySettings.address}</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        <button type="button" className="btn btn-primary" onClick={onClickChangeEmail}>{i18n.SecuritySettings.change}</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="card-inner">
                <div className="between-center flex-wrap g-3">
                  <div className="nk-block-text">
                    <h6>{i18n.SecuritySettings.authentication}</h6>
                    <p>{i18n.SecuritySettings.after}</p>
                  </div>
                  <div className="nk-block-actions flex-shrink-sm-0">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-3 gy-2">
                      <li className="order-md-last">
                        {user.user.isAuthenticated ? <span class="bg-success text-white" style={styleCheck}><em class="icon ni ni-check-thick"></em></span> :
                          <button type="button" className="btn btn-primary" onClick={onClickVerificationRetry}>{i18n.SecuritySettings.activate}</button>
                        }
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {
  updateUser,
}

export const SecuritySettings = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SecuritySettingsComponent)
