import { SET_LANGUAGE, LANGUAGE_LOADING } from '../actions'

const initialState = {
  dictionary: {},
  isLoading: true,
}

export const i18n = (state = initialState, action) => {
  switch (action.type) {
  case LANGUAGE_LOADING:
    return {
      ...state,
      isLoading: action.payload,
    }
  case SET_LANGUAGE:
    return {
      ...state,
      dictionary: action.payload,
      isLoading: false,
    }
  default:
    return state
  }
}
