import React, { useState, useCallback, useEffect } from 'react'
import { A } from 'hookrouter'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'
import moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import axios from 'axios'
import Pagination from 'react-js-pagination'

import { DropdownMenu } from '../shared'
import { TRANSACTION_TYPES } from '../../constants'
import { numberFormat } from '../../utils'

const ActionButtons = ({ transaction, i18n, removeTransaction }) => {
  const onDeleteTransaction = () => Swal.fire({
    title: i18n.Shared.TransactionList.modalDeleteTitle,
    text: i18n.Shared.TransactionList.modalDeleteText,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: i18n.Shared.TransactionList.modalDeleteConfirm,
    cancelButtonText: i18n.Shared.TransactionList.modalDeleteCancel,
    confirmButtonColor: '#e85347',
  }).then(result => {
    if (result.isConfirmed) {
      removeTransaction(transaction.id)
    }
  })

  return (
    <div className="nk-tb-col nk-tb-col-tools">
      <ul className="nk-tb-actions gx-1">
        <li>
          <div className="drodown mr-n1">
            <DropdownMenu
              btn={
                <A href="" className="dropdown-toggle btn btn-icon btn-trigger">
                  <em className="icon ni ni-more-h"></em>
                </A>
              }
              content={
                <div
                  className="dropdown-menu dropdown-menu-right show"
                  style={{
                    position: 'absolute',
                    transform: 'translate3d(-80px, 50px, 0px)',
                    top: '0px',
                    left: '0px',
                    willChange: 'transform',
                  }}
                >
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <A href={`/update-transaction/${transaction.id}`}>
                        <em className="icon ni ni-pen2"></em>
                        <span>{i18n.Shared.TransactionList.editTransaction}</span>
                      </A>
                    </li>
                    <li>
                      <A href="" onClick={() => onDeleteTransaction()}>
                        <em className="icon ni ni-trash"></em>
                        <span>{i18n.Shared.TransactionList.removeTransaction}</span>
                      </A>
                    </li>
                  </ul>
                </div>
              }
            />
          </div>
        </li>
      </ul>
    </div>
  )
}

const TransactionListLoader = ({ count }) => (
  <div className="card card-bordered">
    <div className="card-inner">
      <Skeleton height={23} className="mb-2" />
      <Skeleton height={16} className="mb-3" />
      {new Array(count).map(() => <Skeleton />)}
    </div>
  </div>
)

export const TransactionListComponent = ({
  portfolios,
  size,
  i18n,
}) => {
  const [activePage, setActivePage] = useState(1)
  const [type, setType] = useState(null)

  const [transactions, setTransactions] = useState(false)
  const [transactionTotalCount, setTransactionTotalCount] = useState(0)

  const transactionsRequest = useCallback(async () => {
    const { data, headers } = await axios.get('/transaction', {
      params: {
        page: activePage,
        size,
        ...(type === null ? {} : { type }),
        ...(portfolios ? { portfolio: portfolios.join(',') } : {}),
      },
    })
    setTransactions(data)
    setTransactionTotalCount(+headers['x-total-count'])
  }, [activePage, type, size, portfolios])

  useEffect(() => {
    transactionsRequest()
  }, [transactionsRequest, type])

  useEffect(() => {
    setActivePage(1)
  }, [type])

  const removeTransaction = async id => {
    await axios.delete(`/transaction/${id}`)
    setTransactions(transactions.filter(t => t.id !== id))
  }

  return !transactions ? <TransactionListLoader count={size} /> : (
    <>
      <div className="row g-gs">
        <div className="col-lg-12">
          <div className="card card-bordered card-full">
            <div className="card-inner">
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title"><span className="mr-2">{i18n.Shared.TransactionList.title} ({transactionTotalCount})</span> <A href="/create-transaction" className="link d-none d-sm-inline">{i18n.Shared.TransactionList.createNew}</A></h6>
                </div>
                <div className="card-tools">
                  <ul className="card-tools-nav">
                    {Object.values(TRANSACTION_TYPES).map(t => (
                      <li key={t} className={type === t ? 'active' : ''}>
                        <A href="#" onClick={() => setType(t)}>
                          <span>{i18n.Shared.TransactionList[t]}</span>
                        </A>
                      </li>
                    ))}
                    <li className={type === null ? 'active' : ''}>
                      <A href="" onClick={() => setType(null)}>
                        <span>{i18n.Shared.TransactionList.all}</span>
                      </A>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-inner p-0 border-top">
              <div className="nk-tb-list nk-tb-orders">
                <div className="nk-tb-item nk-tb-head">
                  <div className="nk-tb-col nk-tb-orders-type"><span>{i18n.Shared.TransactionList.type}</span></div>
                  <div className="nk-tb-col"><span>{i18n.Shared.TransactionList.asset}</span></div>
                  <div className="nk-tb-col tb-col-sm"><span>{i18n.Shared.TransactionList.date}</span></div>
                  <div className="nk-tb-col text-right"><span>{i18n.Shared.TransactionList.price}</span></div>
                  <div className="nk-tb-col text-right"><span>{i18n.Shared.TransactionList.count}</span></div>
                  <div className="nk-tb-col text-right tb-col-sm"><span>{i18n.Shared.TransactionList.commission}</span></div>
                  <div className="nk-tb-col text-right tb-col-sm"><span>{i18n.Shared.TransactionList.cost}</span></div>
                  <div className="nk-tb-col text-right"><span></span></div>
                </div>
                {transactions.map(t => (
                  <div className="nk-tb-item" key={t.id}>
                    <div className="nk-tb-col">
                      <span className="tb-lead">{i18n.Shared.TransactionList[t.type]}</span>
                    </div>
                    <div className="nk-tb-col">
                      <span className="tb-lead"><A href={`/assets/${t.category}/${t.ticker}`}>{t.ticker}</A></span>
                    </div>
                    <div className="nk-tb-col tb-col-sm">
                      <span className="tb-sub">{moment(t.date).format('YYYY-MM-DD')}</span>
                    </div>
                    <div className="nk-tb-col text-right">
                      <span className="tb-sub tb-amount">{numberFormat(t.price)}</span>
                    </div>
                    <div className="nk-tb-col text-right">
                      <span className="tb-sub tb-amount">{numberFormat(t.count)} <span>{i18n.Shared.TransactionList[t.category]}</span></span>
                    </div>
                    <div className="nk-tb-col text-right tb-col-sm">
                      <span className="tb-lead">{numberFormat(t.commission)}</span>
                    </div>
                    <div className="nk-tb-col text-right tb-col-sm">
                      <span className="tb-sub tb-amount ">{numberFormat(t.count * t.price)} <span>{t.currency}</span></span>
                    </div>
                    <ActionButtons
                      transaction={t}
                      i18n={i18n}
                      removeTransaction={removeTransaction}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="card-inner-sm border-top text-center d-sm-none">
              <A href="/create-transaction" className="btn btn-link btn-block">{i18n.Shared.TransactionList.createNew}</A>
            </div>
          </div>
        </div>
      </div>
      <div className="row g-gs">
        <div className="col-lg-12 d-flex justify-content-center">
          {transactionTotalCount > size &&
            <Pagination
              activePage={activePage}
              itemsCountPerPage={size}
              totalItemsCount={transactionTotalCount}
              onChange={pageNumber => setActivePage(pageNumber)}
              itemClass="page-item"
              linkClass="page-link"
            />}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {}

export const TransactionList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionListComponent)
