import React from 'react'
import { A, usePath } from 'hookrouter'
import Swal from 'sweetalert2'
import axios from 'axios'

import { DropdownMenu } from '../shared'

const OPEN_SIDEBAR = 'nk-sidebar-mobile nk-sidebar-active'

export const Sidebar = ({
  isOpenSidebar,
  isDarkTheme,
  onChangeTheme,
  user,
  logout,
  i18n,
}) => {
  const openSidebar = isOpenSidebar ? OPEN_SIDEBAR : ''
  const path = usePath()

  const onClickFeedback = async () => {
    const result = await Swal.fire({
      input: 'textarea',
      title: i18n.Feedback.title,
      inputLabel: i18n.Feedback.placeholder,
      confirmButtonText: i18n.Feedback.send,
      inputValidator: value => {
        if (!value) {
          return 'Error.'
        }
      },
    })
    await axios.post('/feedback', { text: result.value, type: '' })
  }

  const onThemeClick = event => {
    event.preventDefault()
    onChangeTheme()
  }

  return (
    <div className={`nk-sidebar ${openSidebar}`} data-content="sidebarMenu">
      <div className="nk-sidebar-bar">
        <div className="nk-apps-brand">
          <A href="/" className="logo-link">
            <img className="logo-light logo-img sidebar-logo-img" src="/reagle.svg" srcSet="/reagle.svg 2x" alt="logo" />
            <img className="logo-dark logo-img sidebar-logo-img" src="/reagle.svg" srcSet="/reagle.svg 2x" alt="logo-dark" />
          </A>
        </div>
        <div className="nk-sidebar-element">
          <div className="nk-sidebar-body">
            <div className="nk-sidebar-content" data-simplebar>
              <div className="nk-sidebar-menu mt-5">
                <ul className="nk-menu apps-menu">
                  <li className={`nk-menu-item ${path === '/dashboard' && 'active current-page'}`}>
                    <A href="/dashboard" className="nk-menu-link nk-menu-switch">
                      <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span>
                    </A>
                  </li>
                  <li className={`nk-menu-item ${path === '/portfolios' && 'active current-page'}`}>
                    <A href="/portfolios" className="nk-menu-link nk-menu-switch">
                      <span className="nk-menu-icon"><em className="icon ni ni-briefcase"></em></span>
                    </A>
                  </li>
                  <li className={`nk-menu-item ${path === '/transactions' && 'active current-page'}`}>
                    <A href="/transactions" className="nk-menu-link nk-menu-switch">
                      <span className="nk-menu-icon"><em className="icon ni ni-tranx"></em></span>
                    </A>
                  </li>
                  <li className={`nk-menu-item ${path === '/watchlist' && 'active current-page'}`}>
                    <A href="/watchlist" className="nk-menu-link nk-menu-switch">
                      <span className="nk-menu-icon"><em className="icon ni ni-star"></em></span>
                    </A>
                  </li>
                  <li className={`nk-menu-item ${path === '/search' && 'active current-page'}`}>
                    <A href="/search" className="nk-menu-link nk-menu-switch">
                      <span className="nk-menu-icon"><em className="icon ni ni-search"></em></span>
                    </A>
                  </li>
                </ul>
              </div>
              {/* <div className="nk-sidebar-footer">
                <ul className="nk-menu nk-menu-md apps-menu">
                  <li className={`nk-menu-item ${path === '/settings' && 'active current-page'}`}>
                    <A href="/settings" className="nk-menu-link" title="Settings"  onClick={onClick}>
                      <span className="nk-menu-icon"><em className="icon ni ni-setting"></em></span>
                    </A>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="nk-sidebar-profile nk-sidebar-profile-fixed dropdown">
              <DropdownMenu
                btn={
                  <A href="">
                    <div className="user-avatar not-close-sidebar">
                      <span className="not-close-sidebar">
                        @{user.user.email.slice(0, 1).toUpperCase()}
                      </span>
                    </div>
                  </A>
                }
                content={
                  <div
                    className="dropdown-menu dropdown-menu-md ml-4 show"
                    style={{
                      position: 'absolute',
                      transform: 'translate3d(40px, -160px, 0px)',
                      top: '0px',
                      left: '0px',
                      willChange: 'transform',
                    }}
                  >
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li>
                          <A href="/settings">
                            <em className="icon ni ni-setting-alt"></em><span>Account Setting</span>
                          </A>
                        </li>
                        <li>
                          <A className={`dark-switch ${!isDarkTheme && 'active'}`} onClick={onThemeClick} href="">
                            <em className="icon ni ni-sun"></em><span>Light Mode</span>
                          </A>
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown-inner">
                      <ul className="link-list">
                        <li>
                          <A href="" onClick={logout}>
                            <em className="icon ni ni-signout"></em><span>{i18n.Sidebar.logout}</span>
                          </A>
                        </li>
                        <li>
                          <A className="nav-link" href="" onClick={onClickFeedback}>
                            <em className="icon ni ni-comments"></em><span>{i18n.MobileFooter.feedback}</span>
                          </A>
                        </li>
                      </ul>
                    </div>
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}
