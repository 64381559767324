import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { A } from 'hookrouter'
import Swal from 'sweetalert2'

import { AssetCryptocurrency, AssetCurrency, AssetETF, AssetStock } from '../assets'
import { ASSET_CATEGORIES } from '../../constants'
import { sortAssetArray } from '../../utils'

const WatchlistPage = ({ i18n }) => {
  const [selectedAsset, setSelectedAsset] = useState(false)
  const [watchlist, setWatchlist] = useState(false)
  const [portfolioAssets, setPortfolioAssets] = useState(false)

  const assetRequest = useCallback(async () => {
    const [{ data: watchlistAssetList }, { data: portfolioAssetList }] = await Promise.all([
      axios.get('/watchlist'),
      axios.get('/assets/details'),
    ])
    const sortedWatchlist = sortAssetArray(watchlistAssetList)
    const sortedPortfolioAssets = sortAssetArray(portfolioAssetList)
    if (sortedWatchlist.length) {
      setSelectedAsset(sortedWatchlist[0])
    } else if (sortedPortfolioAssets.length) {
      setSelectedAsset(sortedPortfolioAssets[0])
    }
    setWatchlist(sortedWatchlist)
    setPortfolioAssets(sortedPortfolioAssets)
  }, [])

  useEffect(() => {
    assetRequest()
  }, [assetRequest])

  const onCreateWatchlist = async () => {
    const result = await Swal.fire({
      title: i18n.Watchlist.modalCreateTitle,
      showCancelButton: true,
      confirmButtonText: i18n.Watchlist.modalCreateConfirm,
      cancelButtonText: i18n.Watchlist.modalCreateCancel,
      html: `
        <input id="swal-category" class="swal2-input">
        <input id="swal-ticker" class="swal2-input">
      `,
      preConfirm: () => [
        document.getElementById('swal-category').value,
        document.getElementById('swal-ticker').value,
      ],
    })
    if (result.isConfirmed) {
      const { data } = await axios.post('/watchlist', {
        category: result.value[0],
        ticker: result.value[1],
      })
      setWatchlist([...watchlist, data])
    }
  }

  const onDeleteWatchlist = async id => {
    const result = await Swal.fire({
      title: i18n.Watchlist.modalDeleteTitle,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: i18n.Watchlist.modalDeleteConfirm,
      cancelButtonText: i18n.Watchlist.modalDeleteCancel,
      confirmButtonColor: '#e85347',
    })
    if (result.isConfirmed) {
      await axios.delete(`/watchlist/${id}`)
      setWatchlist(watchlist.filter(a => a.id !== id))
    }
  }

  return !watchlist || !portfolioAssets ? <div></div> : (
    <div className="nk-block">
      <div className="row g-gs">
        <div className="col-lg-9">
          {selectedAsset && (
            selectedAsset.category === ASSET_CATEGORIES.CRYPTOCURRENCY
              ? <AssetCryptocurrency ticker={selectedAsset.ticker} wide />
              : selectedAsset.category === ASSET_CATEGORIES.CURRENCY
                ? <AssetCurrency ticker={selectedAsset.ticker} wide />
                : selectedAsset.category === ASSET_CATEGORIES.ETF
                  ? <AssetETF ticker={selectedAsset.ticker} wide />
                  : selectedAsset.category === ASSET_CATEGORIES.STOCK
                    ? <AssetStock ticker={selectedAsset.ticker} wide />
                    : <div></div>
          )}
        </div>
        <div className="col-lg-3">
          <div className="card card-bordered card-full" style={{
            maxHeight: '553px',
            width: '100%',
            overflow: 'auto',
            display: 'inline-block',
          }}>
            <div className="card-inner">
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title">
                    <span className="mr-2">{i18n.Watchlist.title} ({watchlist.length})</span>
                    <A href="" onClick={() => onCreateWatchlist()} className="link d-none d-sm-inline">
                      {i18n.Watchlist.addAsset}
                    </A>
                  </h6>
                </div>
              </div>
            </div>
            <div className="card-inner p-0 border-top">
              <div className="nk-tb-list nk-tb-orders">
                <div className="nk-tb-item nk-tb-head">
                  <div className="nk-tb-col nk-tb-orders-type"><span>{i18n.Watchlist.type}</span></div>
                  <div className="nk-tb-col text-right"><span>{i18n.Watchlist.ticker}</span></div>
                  <div className="nk-tb-col text-right"></div>
                </div>
                {watchlist.map(a => (
                  <div className="nk-tb-item" key={a.ticker}>
                    <div className="nk-tb-col">
                      <span className="tb-lead">{i18n.Shared.Assets[a.category]}</span>
                    </div>
                    <div className="nk-tb-col text-right">
                      <span className="tb-lead">
                        <A href="" onClick={() => setSelectedAsset(a)}>{a.ticker}</A>
                      </span>
                    </div>
                    <div className="nk-tb-col text-right">
                      <span className="tb-lead">
                        <A href="" onClick={() => onDeleteWatchlist(a.id)}>
                          <em className="icon ni ni-trash"></em>
                        </A>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="card-inner">
              <div className="card-title-group">
                <div className="card-title">
                  <h6 className="title"><span className="mr-2">{i18n.Watchlist.portfolioAssetsTitle} ({portfolioAssets.length})</span></h6>
                </div>
              </div>
            </div>
            <div className="card-inner p-0 border-top">
              <div className="nk-tb-list nk-tb-orders">
                <div className="nk-tb-item nk-tb-head">
                  <div className="nk-tb-col nk-tb-orders-type"><span>{i18n.Watchlist.type}</span></div>
                  <div className="nk-tb-col text-right"><span>{i18n.Watchlist.ticker}</span></div>
                </div>
                {portfolioAssets.map(a => (
                  <div className="nk-tb-item" key={a.ticker}>
                    <div className="nk-tb-col">
                      <span className="tb-lead">{i18n.Shared.Assets[a.category]}</span>
                    </div>
                    <div className="nk-tb-col text-right">
                      <span className="tb-lead">
                        <A href="" onClick={() => setSelectedAsset(a)}>{a.ticker}</A>
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {}

export const Watchlist = connect(
  mapStateToProps,
  mapDispatchToProps,
)(WatchlistPage)
