import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { A, navigate } from 'hookrouter'
import Swal from 'sweetalert2'
import axios from 'axios'

import { i18nInit } from '../../actions'

const ResetPasswordComponent = ({ i18n, i18nInit }) => {
  const [email, setEmail] = useState('')

  useEffect(() => {
    i18nInit()
  }, [i18nInit])

  const onSubmit = async e => {
    e.preventDefault()
    try {
      await axios.post('/reset-password/init', { email })
      await Swal.fire({
        title: i18n.dictionary.ResetPasswordInit.modalTitleSuccess,
        text: i18n.dictionary.ResetPasswordInit.modalTitleSuccessText,
        icon: 'success',
        confirmButtonText: 'OK',
      })
      navigate('/login')
    } catch {
      Swal.fire({
        title: i18n.dictionary.ResetPasswordInit.modalTitleError,
        text: i18n.dictionary.ResetPasswordInit.modalTitleTextError,
        icon: 'error',
      })
    }
  }

  return i18n.isLoading ? <div></div> : (
    <div className="nk-app-root">
      <div className="nk-main">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body wide-xs">
              <div className="brand-logo pb-4 text-center">
                <A href="/" className="logo-link">
                  <img
                    className="logo-light logo-img logo-img-lg auth-logo"
                    src="/reagle.svg"
                    srcSet="/reagle.svg 2x"
                    alt="logo"
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg auth-logo"
                    src="/reagle.svg"
                    srcSet="/reagle.svg 2x"
                    alt="logo-dark"
                  />
                </A>
              </div>
              <div className="card card-bordered">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h5 className="nk-block-title">{i18n.dictionary.ResetPasswordInit.title}</h5>
                      <div className="nk-block-des">
                        <p>{i18n.dictionary.ResetPasswordInit.titleText}</p>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={onSubmit}>
                    <div className="form-group">
                      <div className="form-label-group">
                        <label className="form-label" htmlFor="default-01">{i18n.dictionary.ResetPasswordInit.email}</label>
                      </div>
                      <input
                        className="form-control form-control-lg"
                        id="default-01"
                        placeholder={i18n.dictionary.ResetPasswordInit.emailPlaceholder}
                        type="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        pattern=".{5,30}"
                        required
                      />
                    </div>
                    <div className="form-group">
                      <button type="submit" className="btn btn-lg btn-primary btn-block">{i18n.dictionary.ResetPasswordInit.send}</button>
                    </div>
                  </form>
                  <div className="form-note-s2 text-center pt-4">
                    <A href="/login"><strong>{i18n.dictionary.ResetPasswordInit.return}</strong></A>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-footer nk-auth-footer-full">
              <div className="container wide-lg">
                <div className="row g-3">
                  <div className="col-lg-12">
                    <div className="nk-block-content text-center text-lg">
                      <p className="text-soft">
                        &copy; {i18n.dictionary.ResetPasswordInit.copy}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  i18n: state.i18n,
})

const mapDispatchToProps = {
  i18nInit,
}

export const ResetPassword = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ResetPasswordComponent)
