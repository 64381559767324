import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { A } from 'hookrouter'
import Chart from 'kaktana-react-lightweight-charts'

import { CHART_INTERVAL, CHART_RANGE } from '../../constants'

export const LightChart = ({
  theme,
  labels,
  data: originalData,
  i18n,
  notUseTitle,
  customTitle,
  interval,
  range,
  onChangeInterval,
  onChangeRange,
}) => {
  const data = [...originalData]
    .filter(d => d.set && d.set.length)
    .sort((a, b) => b.set[b.set.length - 1] - a.set[a.set.length - 1])
  if (data.length > 1) {
    const total = { label: i18n.Shared.CapitalFlow.total, set: [] }
    for (let i = 0; i < data[0].set.length; i++) {
      total.set[i] = data.reduce((res, current) => res += current.set[i], 0)
    }
    data.unshift(total)
  }

  const darkTheme = theme === 'dark'

  return (
    <div className="card card-bordered">
      <div className="card-inner">
        {!notUseTitle && (
          <div className="card-head ui-v2 mb-0">
            <div className="card-title">
              <h5 className="title">{customTitle || i18n.Shared.CapitalFlow.title}</h5>
            </div>
            <div className="card-tools shrink-0 d-none d-sm-block">
              <ul className="nav nav-switch-s2 nav-tabs bg-white mr-2">
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${interval === CHART_INTERVAL.ONE_DAY && 'active'}`}
                    onClick={() => onChangeInterval(CHART_INTERVAL.ONE_DAY)}
                  >1 D</A>
                </li>
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${interval === CHART_INTERVAL.ONE_WEEK && 'active'}`}
                    onClick={() => onChangeInterval(CHART_INTERVAL.ONE_WEEK)}
                  >7 D</A>
                </li>
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${interval === CHART_INTERVAL.ONE_MONTH && 'active'}`}
                    onClick={() => onChangeInterval(CHART_INTERVAL.ONE_MONTH)}
                  >30 D</A>
                </li>
              </ul>
              <ul className="nav nav-switch-s2 nav-tabs bg-white">
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${range === CHART_RANGE.ORIGIN && 'active'}`}
                    onClick={() => onChangeRange(CHART_RANGE.ORIGIN)}
                  >All</A>
                </li>
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${range === CHART_RANGE.Y5 && 'active'}`}
                    onClick={() => onChangeRange(CHART_RANGE.Y5)}
                  >5 Y</A>
                </li>
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${range === CHART_RANGE.Y1 && 'active'}`}
                    onClick={() => onChangeRange(CHART_RANGE.Y1)}
                  >1 Y</A>
                </li>
                <li className="nav-item">
                  <A
                    href=""
                    className={`nav-link ${range === CHART_RANGE.M1 && 'active'}`}
                    onClick={() => onChangeRange(CHART_RANGE.M1)}
                  >1 M</A>
                </li>
              </ul>
            </div>
          </div>
        )}
        <div className="nk-ck3" style={{ marginBottom: '5px' }}>
          <Chart
            // className="chart-account-summary chartjs-render-monitor"
            autoWidth
            height={330}
            lineSeries={data.map(chart => ({
              data: chart.set.map((candle, i) => ({
                time: labels[i],
                value: candle.close,
              })),
            }))}
            // candlestickSeries={data.map(chart => ({
            //   data: chart.set.map((candle, i) => ({
            //     time: labels[i],
            //     open: candle.open,
            //     high: candle.high,
            //     low: candle.low,
            //     close: candle.close,
            //   })),
            // }))}
            options={{}}
            darkTheme={darkTheme}
          />
        </div>
        <div className="mb-5 pb-3"></div>
      </div>
    </div>
  )
}

export const LightChartLoader = () => (
  <div className="card card-bordered">
    <div className="card-inner">
      <Skeleton height={42} className="mb-2" />
      <Skeleton height={35} className="mb-3" />
      <Skeleton height={249} />
    </div>
  </div>
)
