import React, { useState } from 'react'
import { A } from 'hookrouter'
import Skeleton from 'react-loading-skeleton'

import { numberFormat } from '../../utils'

export const AssetList = ({ assets, portfolioCost, i18n }) => {
  const [category, setCategory] = useState('all')

  const filteredAssets = assets
    .filter(a => category === 'all' ? true : a.category === category)
    .sort((a, b) => b.count * b.cost - a.count * a.cost)

  return (
    <div className="card card-bordered card-full">
      <div className="card-inner">
        <div className="card-title-group">
          <div className="card-title">
            <h6 className="title"><span className="mr-2">{i18n.Shared.Assets.assets} ({filteredAssets.length})</span></h6>
          </div>
          <div className="card-tools">
            <ul className="card-tools-nav">
              {[...new Set(assets.map(a => a.category)), 'all'].map(c => (
                <li key={c} className={category === c ? 'active' : ''}>
                  <A href="" onClick={() => setCategory(c)}><span>{i18n.Shared.Assets[c]}</span></A>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="card-inner p-0 border-top">
        <div className="nk-tb-list nk-tb-orders">
          <div className="nk-tb-item nk-tb-head">
            <div className="nk-tb-col nk-tb-orders-type"><span>{i18n.Shared.Assets.type}</span></div>
            <div className="nk-tb-col"><span>{i18n.Shared.Assets.ticker}</span></div>
            <div className="nk-tb-col text-right tb-col-sm"><span>{i18n.Shared.Assets.portfolioPercent}</span></div>
            <div className="nk-tb-col text-right tb-col-sm"><span>{i18n.Shared.Assets.count}</span></div>
            <div className="nk-tb-col text-right tb-col-sm"><span>{i18n.Shared.Assets.cost}</span></div>
            <div className="nk-tb-col text-right tb-col-sm"><span>{i18n.Shared.Assets.purchaseCost}</span></div>
            <div className="nk-tb-col text-right"><span>{i18n.Shared.Assets.sum}</span></div>
            <div className="nk-tb-col text-right"><span>{i18n.Shared.Assets.profit}</span></div>
          </div>
          {filteredAssets.map(a => {
            const sumCost = a.count * a.cost
            const profit = (a.cost - a.purchasePrice) * a.count
            const profitPercent = a.cost && a.purchasePrice ?
              (a.cost - a.purchasePrice) / a.purchasePrice * 100 : 0
            const portfolioPercent = (sumCost / portfolioCost * 100) || 0
            return (
              <div className="nk-tb-item" key={a.ticker}>
                <div className="nk-tb-col">
                  <span className="tb-lead">{i18n.Shared.Assets[a.category]}</span>
                </div>
                <div className="nk-tb-col">
                  <span className="tb-lead"><A href={`/assets/${a.category}/${a.ticker}`}>{a.ticker}</A></span>
                </div>
                <div className="nk-tb-col text-right tb-col-sm">
                  <span className="tb-sub tb-amount">{numberFormat(portfolioPercent)} <span>%</span></span>
                </div>
                <div className="nk-tb-col text-right tb-col-sm">
                  <span className="tb-sub tb-amount">{numberFormat(a.count)}</span>
                </div>
                <div className="nk-tb-col text-right tb-col-sm">
                  <span className="tb-sub tb-amount ">{numberFormat(a.cost)}</span>
                </div>
                <div className="nk-tb-col text-right tb-col-sm">
                  <span className="tb-sub tb-amount ">{numberFormat(a.purchasePrice)}</span>
                </div>
                <div className="nk-tb-col text-right">
                  <span className="tb-sub tb-amount ">{numberFormat(sumCost)}</span>
                </div>
                <div className="nk-tb-col text-right">
                  <span className="tb-sub tb-amount">{numberFormat(profit)} / {numberFormat(profitPercent)}%<em className={`change ${profit >= 0 ? 'text-success' : 'text-danger'} icon ni ni-arrow-long-${profit >= 0 ? 'up' : 'down'}`}></em></span>
                </div>
              </div>
            )
          })}
        </div>
      </div >
    </div >
  )
}

export const AssetListLoader = () => (
  <div className="card card-bordered">
    <div className="card-inner">
      <Skeleton height={23} className="mb-2" />
      <Skeleton height={16} className="mb-3" />
      <Skeleton height={42} />
      <Skeleton height={42} />
      <Skeleton height={42} />
      <Skeleton height={42} />
      <Skeleton height={42} />
    </div>
  </div>
)
