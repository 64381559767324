import React from 'react'
import { A } from 'hookrouter'
import { Bar } from 'react-chartjs-2'
import Skeleton from 'react-loading-skeleton'

import { DropdownMenu } from './dropdown-menu'
import { round, numberFormat } from '../../utils'

export const TotalValue = ({
  title,
  portfolios,
  portfoliosCost,
  user,
  onChangeCurrency,
  i18n,
}) => {
  const baseCost = portfolios.reduce((total, data) => total + data.baseCost, 0)
  const totalCurrentProfit = portfolios.reduce((total, p) => total + p.currentProfit, 0)
  const totalCurrentCost = portfolios.reduce((total, p) => total + p.currentCost, 0)
  const totalCurrentPercent = (totalCurrentProfit / baseCost * 100) || 0

  const dividends = portfolios.reduce((total, c) => total + c.dividends, 0)
  const commission = portfolios.reduce((total, c) => total + c.commissions, 0)

  const totalAllTimeProfit = portfolios
    .reduce((total, p) => total + p.allTimeProfit, 0) - commission
  const totalAllTimeCost = portfolios.reduce((total, p) => total + p.allTimeCost, 0)
  const totalAllTimePercent = totalAllTimeProfit && totalAllTimeCost ?
    totalAllTimeProfit / totalAllTimeCost * 100 : 0
  let totalAllTimePercentRelativelyCurrent = totalAllTimeProfit && baseCost ?
    totalAllTimeProfit / baseCost * 100 : 0

  const totalCost = numberFormat(totalCurrentCost)

  const capitalData = []
  for (const portfolio of Object.values(portfoliosCost.portfolios)) {
    for (const [index, cost] of portfolio.entries()) {
      if (!capitalData[index]) {
        capitalData[index] = 0
      }
      capitalData[index] += cost
    }
  }

  return (
    <div className="card card-bordered">
      <div className="card-inner">
        <div className="nk-wg1">
          <div className="nk-wg1-group g-2">
            <div className="nk-wg1-item mr-xl-4">
              <div className="nk-wg1-title">{title || i18n.Shared.TotalValue.title} / <div className="dropdown">
                <DropdownMenu
                  btn={
                    <A href="" className="dropdown-indicator-caret">
                      {user.currentCurrency}
                    </A>
                  }
                  content={
                    <div
                      className="dropdown-menu dropdown-menu-xxs dropdown-menu-center show"
                      style={{
                        position: 'absolute',
                        transform: 'translate3d(0px, 33px, 0px)',
                        top: '0px',
                        left: '0px',
                        willChange: 'transform',
                      }}
                    >
                      <ul className="link-list-plain sm text-center">
                        {user.user.currencies.map(c => (
                          <li key={c}>
                            <A href="#" onClick={() => onChangeCurrency(c)}>{c}</A>
                          </li>
                        ))}
                      </ul>
                    </div>
                  }
                />
              </div></div>
              <div className="nk-wg1-amount nk-ecwg">
                <div className="amount">{totalCost} <small className="currency currency-btc">{user.currentCurrency}</small></div>
              </div>
            </div>
            <div className="nk-wg1-item">
              {/* <div className="nk-wg1-title">Current</div> */}
              <div className="nk-wg1-group g-2">
                <div className="nk-wg1-sub">
                  <div className="sub-text">
                    <div className="dot" data-bg="#9cabff" style={{ backgroundColor: 'rgb(156, 171, 255)', backgroundPosition: 'initial initial', backgroundRepeat: 'initial initial' }}></div>
                    <span>{i18n.Shared.TotalValue.currentProfit}</span>
                  </div>
                  <div className="lead-text">
                    {numberFormat(totalCurrentProfit)} {user.currentCurrency} / {' '}
                    {numberFormat(totalCurrentPercent)}%<em className={`change ${totalCurrentPercent >= 0 ? 'text-success' : 'text-danger'} icon ni ni-arrow-long-${totalCurrentPercent >= 0 ? 'up' : 'down'}`}></em>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-wg1-item">
              {/* <div className="nk-wg1-title">All Time</div> */}
              <div className="nk-wg1-group g-2">
                <div className="nk-wg1-sub">
                  <div className="sub-text">
                    <div className="dot" data-bg="#a7ccff" style={{ backgroundColor: 'rgb(167, 204, 255)', backgroundPosition: 'initial initial', backgroundRepeat: 'initial initial' }}></div>
                    <span>{i18n.Shared.TotalValue.allTimeProfit}</span>
                  </div>
                  <div className="lead-text">
                    {numberFormat(totalAllTimeProfit)} {user.currentCurrency} / {' '}
                    {numberFormat(totalAllTimePercent)}%<em className={`change ${totalAllTimePercent >= 0 ? 'text-success' : 'text-danger'} icon ni ni-arrow-long-${totalAllTimePercent >= 0 ? 'up' : 'down'}`}></em>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-wg1-item">
              {/* <div className="nk-wg1-title">Something</div> */}
              <div className="nk-wg1-group g-2">
                <div className="nk-wg1-sub">
                  <div className="sub-text">
                    <div className="dot" data-bg="#9cabff" style={{ backgroundColor: 'rgb(156, 171, 255)', backgroundPosition: 'initial initial', backgroundRepeat: 'initial initial' }}></div>
                    <span>{i18n.Shared.TotalValue.baseCostAndPercent}</span>
                  </div>
                  <div className="lead-text">{numberFormat(baseCost)} {user.currentCurrency} | {numberFormat(totalAllTimePercentRelativelyCurrent)}%<em className={`change ${totalAllTimePercent >= 0 ? 'text-success' : 'text-danger'} icon ni ni-arrow-long-${totalAllTimePercent >= 0 ? 'up' : 'down'}`}></em></div>
                </div>
              </div>
            </div>
            <div className="nk-wg1-item">
              {/* <div className="nk-wg1-title">Something</div> */}
              <div className="nk-wg1-group g-2">
                <div className="nk-wg1-sub">
                  <div className="sub-text">
                    <div className="dot" data-bg="#9cabff" style={{ backgroundColor: 'rgb(156, 171, 255)', backgroundPosition: 'initial initial', backgroundRepeat: 'initial initial' }}></div>
                    <span>{i18n.Shared.TotalValue.commissionAndDividends}</span>
                  </div>
                  <div className="lead-text">{numberFormat(commission)} {user.currentCurrency} | {numberFormat(dividends)} {user.currentCurrency}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="invest-top-ck mt-auto"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
          <Bar
            className="iv-plan-purchase chartjs-render-monitor"
            style={{ display: 'block', width: '475px', height: '50px' }}
            width={475}
            height={50}
            data={{
              labels: capitalData.map(() => ''),
              datasets: [{
                backgroundColor: '#e0e4ff',
                barPercentage: 0.7,
                borderColor: 'transparent',
                borderSkipped: 'bottom',
                borderWidth: 2,
                categoryPercentage: 0.7,
                data: capitalData.map(v => round(v)),
                hoverBackgroundColor: '#6576ff',
                hoverBorderColor: 'transparent',
                label: 'Capital',
              }],
            }}
            options={{
              legend: {
                display: false,
                labels: {
                  boxWidth: 30,
                  padding: 20,
                  fontColor: '#6783b8',
                },
              },
              maintainAspectRatio: false,
              tooltips: {
                enabled: true,
                backgroundColor: '#eff6ff',
                titleFontSize: 11,
                titleFontColor: '#6783b8',
                titleMarginBottom: 4,
                bodyFontColor: '#9eaecf',
                bodyFontSize: 10,
                bodySpacing: 3,
                yPadding: 8,
                xPadding: 8,
                footerMarginTop: 0,
                displayColors: false,
              },
              scales: {
                yAxes: [{
                  display: false,
                  stacked: false,
                  ticks: {
                    beginAtZero: true,
                  },
                }],
                xAxes: [{
                  display: false,
                  stacked: false,
                }],
              },
            }}
          />
        </div>
      </div>
    </div>
  )
}

export const TotalValueLoader = () => (
  <div className="card card-bordered">
    <div className="card-inner">
      <Skeleton height={20} className="mb-2" />
      <Skeleton height={30} className="mb-4" />
      <Skeleton height={20} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={40} className="mb-3" />
      <Skeleton height={48} />
    </div>
  </div>
)
