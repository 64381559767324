import React from 'react'
import ReactDOM from 'react-dom'

import 'react-datepicker/dist/react-datepicker.css'
import './assets/css/template.css'
import './assets/css/custom.css'

import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()
