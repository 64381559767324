import axios from 'axios'
import moment from 'moment'

import { CHART_RANGE } from '../constants'

export const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = token
  } else {
    delete axios.defaults.headers.common['Authorization']
  }
}

export const round = (number, decimal = 2) => +number ? +number.toFixed(decimal) : 0

export const numberSeparator = number => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const numberFormat = (number, decimal = 2) => numberSeparator(round(number, decimal))

export const chartDates = range => ({
  from: CHART_RANGE.ORIGIN === range ?
    CHART_RANGE.ORIGIN : moment().subtract(range, 'months').toDate(),
  to: moment().toDate(),
})

export const selectStylesHandler = darkTheme => ({
  menuList: (provided) => ({
    ...provided,
    backgroundColor: darkTheme ? '#18212d' : '#fff',
    border: '1px solid #3b526f',
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: darkTheme ? '#18212d' : '#fff',
    borderColor: '#3b526f',
  }),
  valueContainer: (provided) => ({
    ...provided,
    borderRadius: '4px',
    backgroundColor: darkTheme ? '#18212d' : '#fff',
    color: darkTheme ? '#fff' : 'hsl(0,0%,20%)',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    backgroundColor: '#3fbd9a',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    borderRadius: '4px',
    backgroundColor: darkTheme ? '#18212d' : '#fff',
    color: '#3fbd9a',
  }),
  option: (provided) => ({
    ...provided,
    color: darkTheme ? '#fff' : 'hsl(0,0%,20%)',
    marginBottom: '0.125rem',
    padding: '0.375rem 0.75rem',
  }),
  container: (provided) => ({
    ...provided,
    borderColor: '#3b526f',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#fff',
  }),
  input: (provided) => ({
    ...provided,
    color: darkTheme ? '#fff' : 'hsl(0,0%,20%)',
  }),
})

export const selectThemeHandler = () => theme => ({
  ...theme,
  borderRadius: '4px',
  colors: {
    ...theme.colors,
    primary: '#101924',
    primary25: '#101924',
  },
})

export const sortArray = (array, field) => {
  return [...array].sort((a, b) => a[field].localeCompare(b[field]))
}

export const sortAssetArray = (array, tickerField = 'ticker') => {
  const modified = array
    .map(a => ({
      ...a,
      _ticker: a[tickerField].includes(':') ? a[tickerField].split(':')[1] : a[tickerField],
    }))
  return sortArray(modified, '_ticker')
}
