import { SET_CURRENT_USER, SET_CURRENT_CURRENCY, SET_CURRENT_THEME } from '../actions'

const initialState = {
  isAuthenticated: false,
  user: {},
  currentCurrency: '',
  theme: 'dark',
}

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_PROD !== 'true') {
  initialState.isAuthenticated = true
  initialState.user = {
    id: 'uuid',
    email: 'dev@dev',
    currencies: ['USD', 'RUB', 'JPY'],
    language: 'en',
  }
  initialState.currentCurrency = 'USD'
  initialState.theme = 'dark'
}

export const user = (state = initialState, action) => {
  switch (action.type) {
  case SET_CURRENT_USER:
    return {
      ...state,
      isAuthenticated: Object.keys(action.payload).length !== 0,
      user: action.payload,
      currentCurrency: action.payload.currencies[0],
    }
  case SET_CURRENT_CURRENCY:
    return {
      ...state,
      currentCurrency: action.payload,
    }
  case SET_CURRENT_THEME:
    return {
      ...state,
      theme: action.payload,
    }
  default:
    return state
  }
}
