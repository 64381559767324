import React, { useState } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { navigate } from 'hookrouter'

export const CreatePortfolioComponent = ({ i18n }) => {
  const [name, setName] = useState('')

  const onSave = async e => {
    e.preventDefault()
    const { data } = await axios.post('/portfolio', { name })
    navigate(`/dashboard?portfolios=${data.id}`)
  }

  return (
    <div className="container-xl wide-lg">
      <div className="nk-content-body">
        <div className="buysell wide-xs m-auto">
          <div className="buysell-nav text-center"></div>
          <div className="buysell-title text-center">
            <h2 className="title">{i18n.CreatePortfolio.title}</h2>
          </div>
          <div className="buysell-block">
            <form onSubmit={onSave} className="buysell-field form-group">
              <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="buysell-amount">{i18n.CreatePortfolio.name}</label>
                </div>
                <div className="form-control-group">
                  <input
                    style={{ fontSize: '13px', padding: '20px', height: '0' }}
                    type="text"
                    className="form-control form-control form-control-number"
                    id="buysell-amount"
                    name="bs-amount"
                    value={name}
                    onChange={e => setName(e.target.value)}
                    maxLength="50"
                    required
                  />
                </div>
              </div>
              <div style={{ marginLeft: 'auto', marginTop: '25px', marginRight: 'auto', width: '34%' }} className="form-control-group">
                <button style={{ fontSize: '13px' }} className="btn btn-lg btn-block btn-primary" data-toggle="modal"
                  data-target="#buy-coin">{i18n.CreatePortfolio.save}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  portfolio: state.portfolio,
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {}

export const CreatePortfolio = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreatePortfolioComponent)
