import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import Skeleton from 'react-loading-skeleton'

import { DEFAULT_COLORS } from '../../constants'
import { round } from '../../utils'

export const Ratio = ({ title, data }) => {
  const sorted = Object
    .entries(data)
    .sort(([, a], [, b]) => b - a)

  const labels = []
  const values = []
  for (const p of sorted) {
    labels.push(p[0] + ' %')
    values.push(p[1])
  }

  const percent = values.reduce((total, v) => total + v, 0)

  return (
    <div className="card card-bordered h-100">
      <div className="card-inner h-100 stretch flex-column">
        <div className="card-title-group">
          <div className="card-title card-title-lg">
            <h5 className="title">{title}</h5>
          </div>
        </div>
        <div className="device-status my-auto">
          <div className="device-status-ck"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
            <Doughnut
              className="analytics-doughnut chartjs-render-monitor"
              style={{ display: 'block', width: '251px', height: '200px' }}
              width={251}
              height={200}
              data={{
                labels,
                datasets: [{
                  data: values.map(v => round(v / percent * 100)),
                  backgroundColor: DEFAULT_COLORS.slice(0, labels.length),
                }],
              }}
              options={{
                legend: {
                  display: false,
                  labels: {
                    boxWidth: 12,
                    padding: 20,
                    fontColor: '#6783b8',
                  },
                },
                rotation: -1.5,
                cutoutPercentage: 70,
                maintainAspectRatio: false,
                tooltips: {
                  enabled: true,
                  backgroundColor: '#fff',
                  borderColor: '#eff6ff',
                  borderWidth: 2,
                  titleFontSize: 13,
                  titleFontColor: '#6783b8',
                  titleMarginBottom: 6,
                  bodyFontColor: '#9eaecf',
                  bodyFontSize: 12,
                  bodySpacing: 4,
                  yPadding: 10,
                  xPadding: 10,
                  footerMarginTop: 0,
                  displayColors: false,
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export const RatioLoader = () => (
  <div className="card card-bordered">
    <div className="card-inner">
      <Skeleton height={22} className="mb-2" />
      <Skeleton circle={true} height={297} className="mb-3" />
    </div>
  </div>
)
