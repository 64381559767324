import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { A, navigate } from 'hookrouter'
import axios from 'axios'
import Select from 'react-select'
import DatePicker from 'react-datepicker'

import { DropdownMenu } from '../shared'
import { ASSET_CATEGORIES, TRANSACTION_TYPES, MIN_DATE } from '../../constants'
import { selectStylesHandler, selectThemeHandler } from '../../utils'

const UpdateTransactionPage = ({ transactionId, user, i18n }) => {
  const [id, setId] = useState('')
  const [ticker, setTicker] = useState('')
  const [date, setDate] = useState('')
  const [category, setCategory] = useState(ASSET_CATEGORIES.STOCK)
  const [type, setType] = useState(TRANSACTION_TYPES.BUY)
  const [transactionPortfolio, setPortfolio] = useState('')
  const [count, setCount] = useState('')
  const [price, setPrice] = useState('')
  const [commission, setCommission] = useState('0')
  const [currency, setCurrency] = useState(user.currentCurrency)

  const [portfolios, setPortfolios] = useState(false)
  const [percent, setPercent] = useState(false)

  const [button, setButton] = useState(false)

  const portfoliosRequest = useCallback(async () => {
    const { data } = await axios.get('/portfolio')
    setPortfolios(data)
  }, [])

  const transactionRequest = useCallback(async () => {
    const { data } = await axios.get(`/transaction/${transactionId}`)
    setId(data.id)
    setTicker(data.ticker || ticker)
    setDate(new Date(data.date))
    setCategory(data.category || category)
    setType(data.type || type)
    setPortfolio(data.portfolio || transactionPortfolio)
    setCount(data.count || count)
    setPrice(data.price || price)
    setCommission(+data.commission.toFixed(5) || commission)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId])

  useEffect(() => {
    portfoliosRequest()
    transactionRequest()
  }, [portfoliosRequest, transactionRequest])

  const onUpdate = async e => {
    e.preventDefault()
    setButton('disabled')
    const transactionCommission = percent
      ? +count * +price * +commission / 100
      : +commission
    const { data } = await axios.put('/transaction', {
      id,
      ticker,
      date,
      category,
      type,
      count: +count,
      price: +price,
      commission: transactionCommission || 0,
      currency,
      portfolio: transactionPortfolio,
    })
    navigate(`/dashboard?portfolios=${data.portfolio}`)
  }

  const selectStyles = selectStylesHandler(user.theme === 'dark')
  const selectTheme = selectThemeHandler()

  return !portfolios ? <div></div> : (
    <div className="content-page wide-xs m-auto" >
      <div className="nk-block">
        <div className="card card-bordered">
          <div className="card-aside-sm">
            <div className="card-content wide-xs m-auto">
              <div className="card-inner" style={{ justifyContent: 'center' }}>
                <div className="nk-block-head">
                  <h5 className="title text-center">{i18n.UpdateTransaction.title}</h5>
                </div>
                <form onSubmit={onUpdate} className="buysell-field form-group">
                  <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.UpdateTransaction.ticker}</label>
                    </div>
                    <input
                      style={{ fontSize: '13px', padding: '20px', height: '0' }}
                      type="text"
                      className="form-control"
                      name="bs-amount"
                      value={ticker}
                      onChange={e => setTicker(e.target.value)}
                    />
                  </div>
                  <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.UpdateTransaction.date}</label>
                    </div>
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-right">
                        <em className="icon ni ni-calendar-alt"></em>
                      </div>
                      <DatePicker
                        selected={date}
                        onChange={date => setDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        showTimeInput
                        minDate={MIN_DATE}
                        maxDate={new Date()}
                        locale={i18n.UpdateTransaction.datePickerLocale}
                        dateFormat={i18n.UpdateTransaction.datePickerFormat}
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.UpdateTransaction.category}</label>
                    </div>
                    <Select
                      defaultValue={{ value: type, label: i18n.UpdateTransaction[category] }}
                      options={[
                        {
                          value: ASSET_CATEGORIES.STOCK,
                          label: i18n.UpdateTransaction.stock,
                        },
                        {
                          value: ASSET_CATEGORIES.ETF,
                          label: i18n.UpdateTransaction.etf,
                        },
                        {
                          value: ASSET_CATEGORIES.CURRENCY,
                          label: i18n.UpdateTransaction.currency,
                        },
                        {
                          value: ASSET_CATEGORIES.CRYPTOCURRENCY,
                          label: i18n.UpdateTransaction.cryptocurrency,
                        },
                        {
                          value: ASSET_CATEGORIES.OTHER,
                          label: i18n.UpdateTransaction.other,
                        },
                      ]}
                      onChange={(options) => setCategory(options.value)}
                      isSearchable={false}
                      isFocused={true}
                      styles={selectStyles}
                      theme={selectTheme}
                    />
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.UpdateTransaction.type}</label>
                    </div>
                    <Select
                      defaultValue={{ value: type, label: i18n.UpdateTransaction[type] }}
                      options={[
                        {
                          value: TRANSACTION_TYPES.BUY,
                          label: i18n.UpdateTransaction.buy,
                        },
                        {
                          value: TRANSACTION_TYPES.SELL,
                          label: i18n.UpdateTransaction.sell,
                        },
                        {
                          value: TRANSACTION_TYPES.DIVIDEND,
                          label: i18n.UpdateTransaction.dividend,
                        },
                        {
                          value: TRANSACTION_TYPES.TAX,
                          label: i18n.UpdateTransaction.tax,
                        },
                      ]}
                      onChange={(options) => setType(options.value)}
                      isSearchable={false}
                      isFocused={true}
                      styles={selectStyles}
                      theme={selectTheme}
                    />
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.UpdateTransaction.portfolio}</label>
                    </div>
                    <Select
                      defaultValue={{ label: portfolios[0].name, value: portfolios[0].id }}
                      options={portfolios.map(p => ({ value: p.id, label: p.name }))}
                      onChange={(options) => setPortfolio(options.value)}
                      isSearchable={false}
                      isFocused={true}
                      styles={selectStyles}
                      theme={selectTheme}
                    />
                  </div>
                  <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.UpdateTransaction.count}</label>
                    </div>
                    <div className="form-control-group">
                      <input
                        style={{ fontSize: '13px', padding: '20px', height: '0' }}
                        type="text"
                        className="form-control form-control-lg form-control-number"
                        id="buysell-amount"
                        name="bs-amount"
                        value={count}
                        onChange={e => setCount(e.target.value)}
                        pattern="\d+(.\d{0,})?"
                        required
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.UpdateTransaction.price}</label>
                    </div>
                    <div className="form-control-group">
                      <input
                        style={{ fontSize: '13px', padding: '20px', height: '0' }}
                        type="text"
                        className="form-control form-control-lg form-control-number"
                        id="buysell-amount"
                        name="bs-amount"
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                        pattern="\d+(.\d{0,})?"
                      />
                      <div className="form-dropdown">
                        <div className="dropdown">
                          <DropdownMenu
                            btn={<A href="" className="dropdown-indicator-caret">{currency}</A>}
                            content={
                              <div className="dropdown-menu dropdown-menu-xxs dropdown-menu-right text-center show">
                                <ul className="link-list-plain">
                                  {user.user.currencies.map(c => (
                                    <li key={c}><A href="" onClick={() => setCurrency(c)}>{c}</A></li>
                                  ))}
                                </ul>
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.UpdateTransaction.commission}</label>
                    </div>
                    <div className="form-control-group">
                      <input
                        style={{ fontSize: '13px', padding: '20px', height: '0' }}
                        type="text"
                        className="form-control form-control-lg form-control-number"
                        id="buysell-amount"
                        name="bs-amount"
                        value={commission}
                        onChange={e => setCommission(e.target.value)}
                        pattern="\d+(.\d{0,})?"
                      />
                      <div class="form-dropdown">
                        <div class="custom-control custom-switch">
                          <input onClick={() => setPercent(!percent)} type="checkbox" class="custom-control-input" id="customSwitch6" />
                          <label class="custom-control-label" for="customSwitch6" style={{ color: 'rgb(84, 198, 167)' }}>{i18n.UpdateTransaction.percent}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginLeft: 'auto', marginTop: '25px', marginRight: 'auto', width: '34%' }} className="form-control-group">
                    <button style={{ fontSize: '13px' }} className={`btn btn-lg btn-block btn-primary ${button}`} data-toggle="modal"
                      data-target="#buy-coin">{i18n.UpdateTransaction.update}</button>
                  </div>
                  <span className="form-note">{i18n.UpdateTransaction.cashTransactionText}</span>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  user: state.user,
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {}

export const UpdateTransaction = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateTransactionPage)
