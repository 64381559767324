import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import axios from 'axios'
import { navigate, getQueryParams } from 'hookrouter'
import Select from 'react-select'
import Skeleton from 'react-loading-skeleton'

import { setCurrentCurrency } from '../../actions'
import {
  TotalValue,
  TotalValueLoader,
  CapitalFlow,
  Ratio,
  TransactionList,
  PortfolioList,
  CapitalFlowLoader,
  RatioLoader,
  PortfolioListLoader,
  AssetList,
  AssetListLoader,
} from '../shared'
import { CHART_RANGE, CHART_INTERVAL } from '../../constants'
import { chartDates, selectStylesHandler, selectThemeHandler } from '../../utils'

const DashboardComponent = ({ user, setCurrentCurrency, i18n }) => {
  const [portfolioList, setPortfolioList] = useState(false)
  const [totalValuePortfolios, setTotalValuePortfolios] = useState(false)
  const [totalValuePortfolioCharts, setTotalValuePortfolioCharts] = useState(false)
  const [portfolioCharts, setPortfolioCharts] = useState(false)
  const [assets, setAssets] = useState(false)
  const [assetCharts, setAssetCharts] = useState(false)

  const [selectedPorfolios, setSelectedPorfolios] = useState([])
  const [range, setRange] = useState(CHART_RANGE.Y1)
  const [interval, setInterval] = useState(CHART_INTERVAL.ONE_MONTH)

  const portfolioListRequest = useCallback(async () => {
    const { data } = await axios.get('/portfolio')
    const query = getQueryParams()
    if (query.portfolios) {
      setSelectedPorfolios(query.portfolios.split(',').map(portfolioId => {
        const portfilio = data.find(v => v.id === portfolioId)
        return { value: portfilio.id, label: portfilio.name }
      }))
    }
    setPortfolioList(data)
  }, [])

  const totalValuePortfoliosRequest = useCallback(async () => {
    const params = { currency: user.currentCurrency }
    if (selectedPorfolios.length) {
      params.portfolios = selectedPorfolios.map(p => p.value).join(',')
    }
    const { data } = await axios.get('/portfolio/details', { params })
    setTotalValuePortfolios(data)
  }, [user.currentCurrency, selectedPorfolios])

  const totalValuePortfolioChartsRequest = useCallback(async () => {
    const params = {
      ...chartDates(CHART_RANGE.Y1),
      interval: CHART_INTERVAL.ONE_MONTH,
      currency: user.currentCurrency,
    }
    if (selectedPorfolios.length) {
      params.portfolios = selectedPorfolios.map(p => p.value).join(',')
    }
    const { data } = await axios.get('/portfolio/details/candles', { params })
    setTotalValuePortfolioCharts(data)
  }, [user.currentCurrency, selectedPorfolios])

  const portfolioChartsRequest = useCallback(async () => {
    const params = {
      ...chartDates(range),
      interval,
      currency: user.currentCurrency,
    }
    if (selectedPorfolios.length) {
      params.portfolios = selectedPorfolios.map(p => p.value).join(',')
    }
    const { data } = await axios.get('/portfolio/details/candles', { params })
    setPortfolioCharts(data)
  }, [range, interval, user.currentCurrency, selectedPorfolios])

  const assetsRequest = useCallback(async () => {
    const params = { currency: user.currentCurrency }
    if (selectedPorfolios.length) {
      params.portfolios = selectedPorfolios.map(p => p.value).join(',')
    }
    const { data } = await axios.get('/assets/details', { params })
    setAssets(data)
  }, [user.currentCurrency, selectedPorfolios])

  const assetChartsRequest = useCallback(async () => {
    const params = {
      ...chartDates(range),
      interval,
      currency: user.currentCurrency,
    }
    if (selectedPorfolios.length) {
      params.portfolios = selectedPorfolios.map(p => p.value).join(',')
    }
    const { data } = await axios.get('/assets/details/candles', { params })
    setAssetCharts(data)
  }, [range, interval, user.currentCurrency, selectedPorfolios])

  useEffect(() => {
    portfolioListRequest()
  }, [portfolioListRequest])

  useEffect(() => {
    if (!portfolioList) {
      return
    }
    totalValuePortfolioChartsRequest()
  }, [totalValuePortfolioChartsRequest, user.currentCurrency, portfolioList])

  useEffect(() => {
    if (!portfolioList) {
      return
    }
    totalValuePortfoliosRequest()
    assetsRequest()
  }, [
    totalValuePortfoliosRequest,
    assetsRequest,
    user.currentCurrency,
    selectedPorfolios,
    portfolioList,
  ])

  useEffect(() => {
    if (!portfolioList) {
      return
    }
    portfolioChartsRequest()
    assetChartsRequest()
  }, [
    portfolioChartsRequest,
    assetChartsRequest,
    user.currentCurrency,
    selectedPorfolios,
    portfolioList,
  ])

  const removePortfolio = async id => {
    await axios.delete(`/portfolio/${id}`)
    setTotalValuePortfolios(totalValuePortfolios.filter(p => p.id !== id))
  }

  const updatePortfolio = async portfolio => {
    const { data } = await axios.put('/portfolio', portfolio)
    navigate(`/dashboard?portfolios=${data.id}`)
  }

  const selectStyles = selectStylesHandler(user.theme === 'dark')
  const selectTheme = selectThemeHandler()

  return (
    <div className="nk-block">
      <div className="row g-gs d-flex justify-content-center">
        <div className="col-lg-4">
          {!portfolioList ? <Skeleton height={30} className="mb-1" /> : (
            <div className="form-group">
              <Select
                value={selectedPorfolios}
                options={portfolioList.map(p => ({ value: p.id, label: p.name }))}
                onChange={options => setSelectedPorfolios(options || [])}
                placeholder="Select portfolios..."
                isSearchable={false}
                isFocused={true}
                isMulti
                styles={selectStyles}
                theme={selectTheme}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row g-gs">
        <div className="col-lg-4 col-xl-3">
          {!totalValuePortfolios || !totalValuePortfolioCharts ?
            <TotalValueLoader /> :
            <TotalValue
              i18n={i18n}
              portfolios={totalValuePortfolios}
              portfoliosCost={totalValuePortfolioCharts}
              user={user}
              onChangeCurrency={currency => setCurrentCurrency(currency)}
            />}
        </div>
        <div className="col-lg-8 col-xl-9">
          {!totalValuePortfolios || !portfolioCharts ?
            <CapitalFlowLoader /> :
            <CapitalFlow
              theme={user.theme}
              i18n={i18n}
              labels={portfolioCharts.dates.map(d => moment(d).format('YYYY-MM-DD'))}
              data={totalValuePortfolios.map(p => ({
                label: p.name,
                set: portfolioCharts.portfolios[p.id],
              }))}
              range={range}
              interval={interval}
              onChangeRange={setRange}
              onChangeInterval={setInterval}
            />
          }
        </div>
        <div className="col-lg-9">
          {!totalValuePortfolios || !portfolioCharts ?
            <PortfolioListLoader /> :
            <PortfolioList
              portfolios={totalValuePortfolios}
              portfolioCharts={portfolioCharts}
              removePortfolio={removePortfolio}
              updatePortfolio={updatePortfolio}
            />}
        </div>
        <div className="col-lg-3">
          {!totalValuePortfolios ?
            <RatioLoader /> :
            <Ratio
              title={i18n.Home.portfoliosRatio}
              data={totalValuePortfolios.reduce((res, p) => {
                res[p.name] = p.currentCost
                return res
              }, {})}
            />}
        </div>
        <div className="col-lg-12 col-xl-12">
          {!assetCharts ?
            <CapitalFlowLoader /> :
            <CapitalFlow
              theme={user.theme}
              i18n={i18n}
              labels={Object.values(assetCharts.dates).map(d => moment(d).format('YYYY-MM-DD'))}
              data={Object.entries(assetCharts.assets).map(([key, value]) => ({
                label: key,
                set: value,
              }))}
              range={range}
              interval={interval}
              onChangeRange={setRange}
              onChangeInterval={setInterval}
            />}
        </div>
        <div className="col-lg-12">
          {!assets ?
            <AssetListLoader /> :
            <AssetList
              i18n={i18n}
              assets={assets}
              portfolioCost={assets.reduce((total, a) => total + a.cost * a.count, 0)}
            />}
        </div>
        <div className="col-lg-6">
          {!assets ?
            <RatioLoader /> :
            <Ratio
              title={i18n.Dashboard.categoryRatio}
              data={assets.reduce((res, a) => {
                const category = i18n.Dashboard[a.category]
                if (!res[category]) {
                  res[category] = 0
                }
                res[category] += a.count * a.cost
                return res
              }, {})}
            />}
        </div>
        <div className="col-lg-6">
          {!assets ?
            <RatioLoader /> :
            <Ratio
              title={i18n.Dashboard.tickerRatio}
              data={assets.reduce((res, a) => {
                res[a.ticker] = a.count * a.cost
                return res
              }, {})}
            />}
        </div>
        <div className="col-lg-12">
          <TransactionList
            size={10}
            portfolios={selectedPorfolios.length && selectedPorfolios.map(p => p.value)}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {
  setCurrentCurrency,
}

export const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardComponent)
