import React from 'react'
import { A, usePath } from 'hookrouter'
import { connect } from 'react-redux'

export const SettingsLayoutComponent = ({ children, i18n }) => {
  const path = usePath()

  return (
    <div className="nk-content-inner">
      <div className="nk-content-body">
        <div className="nk-block">
          <div className="card card-bordered">
            <div className="card-aside-wrap">
              <div className="card-content">
                <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                  <li className="nav-item">
                    <A className={`nav-link ${path === '/settings' && 'active'}`} href="/settings">
                      <em className="icon ni ni-opt-alt-fill"></em><span>{i18n.SettingsLayout.general}</span>
                    </A>
                  </li>
                  <li className="nav-item">
                    <A className={`nav-link ${path === '/settings/security' && 'active'}`} href="/settings/security">
                      <em className="icon ni ni-lock-alt-fill"></em><span>{i18n.SettingsLayout.security}</span>
                    </A>
                  </li>
                </ul>
                {children}
              </div>
              {/* <div className="card-aside card-aside-right user-aside toggle-slide toggle-slide-right toggle-break-xxl toggle-screen-xxl" data-content="userAside" data-toggle-screen="xxl" data-toggle-overlay="true" data-toggle-body="true">
                <div className="card-inner-group" data-simplebar="init"><div className="simplebar-wrapper" style="margin: 0px;"><div className="simplebar-height-auto-observer-wrapper"><div className="simplebar-height-auto-observer"></div></div><div className="simplebar-mask"><div className="simplebar-offset" style="right: 0px; bottom: 0px;"><div className="simplebar-content-wrapper" style="height: auto; overflow: hidden scroll;"><div className="simplebar-content" style="padding: 0px;">
                  <div className="card-inner">
                    <div className="user-card user-card-s2">
                      <div className="user-avatar lg bg-primary">
                        <span>AB</span>
                      </div>
                      <div className="user-info">
                        <div className="badge badge-outline-light badge-pill ucap">Investor</div>
                        <h5>Abu Bin Ishtiyak</h5>
                        <span className="sub-text">info@softnio.com</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner card-inner-sm">
                    <ul className="btn-toolbar justify-center gx-1">
                      <li><a href="#" className="btn btn-trigger btn-icon"><em className="icon ni ni-shield-off"></em></a></li>
                      <li><a href="#" className="btn btn-trigger btn-icon"><em className="icon ni ni-mail"></em></a></li>
                      <li><a href="#" className="btn btn-trigger btn-icon"><em className="icon ni ni-download-cloud"></em></a></li>
                      <li><a href="#" className="btn btn-trigger btn-icon"><em className="icon ni ni-bookmark"></em></a></li>
                      <li><a href="#" className="btn btn-trigger btn-icon text-danger"><em className="icon ni ni-na"></em></a></li>
                    </ul>
                  </div>
                  <div className="card-inner">
                    <div className="overline-title-alt mb-2">In Account</div>
                    <div className="profile-balance">
                      <div className="profile-balance-group gx-4">
                        <div className="profile-balance-sub">
                          <div className="profile-balance-amount">
                            <div className="number">2,500.00 <small className="currency currency-usd">USD</small></div>
                          </div>
                          <div className="profile-balance-subtitle">Invested Amount</div>
                        </div>
                        <div className="profile-balance-sub">
                          <span className="profile-balance-plus text-soft"><em className="icon ni ni-plus"></em></span>
                          <div className="profile-balance-amount">
                            <div className="number">1,643.76</div>
                          </div>
                          <div className="profile-balance-subtitle">Profit Earned</div>
                        </div>
                      </div>
                      <div className="profile-balance-ck">
                        <canvas id="profileBalance" className="profile-balance-chart"></canvas>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <div className="row text-center">
                      <div className="col-4">
                        <div className="profile-stats">
                          <span className="amount">23</span>
                          <span className="sub-text">Total Order</span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="profile-stats">
                          <span className="amount">20</span>
                          <span className="sub-text">Complete</span>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="profile-stats">
                          <span className="amount">3</span>
                          <span className="sub-text">Progress</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-2">Additional</h6>
                    <div className="row g-3">
                      <div className="col-6">
                        <span className="sub-text">User ID:</span>
                        <span>UD003054</span>
                      </div>
                      <div className="col-6">
                        <span className="sub-text">Last Login:</span>
                        <span>15 Feb, 2019 01:02 PM</span>
                      </div>
                      <div className="col-6">
                        <span className="sub-text">KYC Status:</span>
                        <span className="lead-text text-success">Approved</span>
                      </div>
                      <div className="col-6">
                        <span className="sub-text">Register At:</span>
                        <span>Nov 24, 2019</span>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner">
                    <h6 className="overline-title-alt mb-3">Groups</h6>
                    <ul className="g-1">
                      <li className="btn-group">
                        <a className="btn btn-xs btn-light btn-dim" href="#">investor</a>
                        <a className="btn btn-xs btn-icon btn-light btn-dim" href="#"><em className="icon ni ni-cross"></em></a>
                      </li>
                      <li className="btn-group">
                        <a className="btn btn-xs btn-light btn-dim" href="#">support</a>
                        <a className="btn btn-xs btn-icon btn-light btn-dim" href="#"><em className="icon ni ni-cross"></em></a>
                      </li>
                      <li className="btn-group">
                        <a className="btn btn-xs btn-light btn-dim" href="#">another tag</a>
                        <a className="btn btn-xs btn-icon btn-light btn-dim" href="#"><em className="icon ni ni-cross"></em></a>
                      </li>
                    </ul>
                  </div>
                </div></div></div></div><div className="simplebar-placeholder" style="width: 299px; height: 965px;"></div></div><div className="simplebar-track simplebar-horizontal" style="visibility: hidden;"><div className="simplebar-scrollbar" style="width: 0px; display: none;"></div></div><div className="simplebar-track simplebar-vertical" style="visibility: visible;"><div className="simplebar-scrollbar" style="height: 256px; transform: translate3d(0px, 0px, 0px); display: block;"></div></div></div><!-- .card-inner -->
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {}

export const SettingsLayout = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SettingsLayoutComponent)