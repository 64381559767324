import React from 'react'
import { A } from 'hookrouter'
import { connect } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import { Line } from 'react-chartjs-2'
import Swal from 'sweetalert2'

import { DropdownMenu } from '../shared'
import { round, numberFormat } from '../../utils'
import { DEFAULT_COLORS } from '../../constants'

const ActionButtons = ({ portfolio, i18n, removePortfolio, updatePortfolio }) => {
  const onDeletePortfolio = portfolioId => Swal.fire({
    title: i18n.Shared.PortfolioList.modalDeleteTitle,
    text: i18n.Shared.PortfolioList.modalDeleteText,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: i18n.Shared.PortfolioList.modalDeleteConfirm,
    cancelButtonText: i18n.Shared.PortfolioList.modalDeleteCancel,
    confirmButtonColor: '#e85347',
  }).then(result => {
    if (result.isConfirmed) {
      removePortfolio(portfolioId)
    }
  })

  const onUpdatePortfolio = portfolio => Swal.fire({
    title: i18n.Shared.PortfolioList.modalUpdateEdit,
    inputValue: portfolio.name,
    input: 'text',
    showCancelButton: true,
    confirmButtonText: i18n.Shared.PortfolioList.modalUpdate,
    confirmButtonColor: '#1ee0ac',
    inputAttributes: {
      maxlength: 20,
      autocapitalize: 'off',
      autocorrect: 'off',
    },
  }).then(result => {
    if (result.isConfirmed) {
      updatePortfolio({ ...portfolio, name: result.value })
    }
  })

  return (
    <div className="nk-tb-col nk-tb-change">
      <ul className="nk-tb-actions gx-1">
        <li>
          <div className="drodown mr-n1">
            <DropdownMenu
              btn={
                <A href="" className="dropdown-toggle btn btn-icon btn-trigger">
                  <em className="icon ni ni-more-h"></em>
                </A>
              }
              content={
                <div
                  className="dropdown-menu dropdown-menu-right show"
                  style={{
                    position: 'absolute',
                    transform: 'translate3d(-100px, 50px, 0px)',
                    top: '0px',
                    left: '0px',
                    willChange: 'transform',
                  }}
                >
                  <ul className="link-list-opt no-bdr">
                    <li>
                      <A href="" onClick={() => onUpdatePortfolio(portfolio)}><em className="icon ni ni-pen2"></em><span>{i18n.Shared.PortfolioList.edit}</span></A>
                    </li>
                    <li>
                      <A href="" onClick={() => onDeletePortfolio(portfolio.id)}>
                        <em className="icon ni ni-trash"></em>
                        <span>{i18n.Shared.PortfolioList.remove}</span>
                      </A>
                    </li>
                  </ul>
                </div>
              }
            />
          </div>
        </li>
      </ul>
    </div>
  )
}

export const PortfolioListComponent = ({
  updatePortfolio,
  removePortfolio,
  portfolios,
  portfolioCharts,
  i18n,
}) => {
  return (
    <div className="card card-bordered h-100">
      <div className="card-inner mb-n2">
        <div className="card-title-group">
          <div className="card-title card-title-lg">
            <h5 className="title"><span className="mr-2">{i18n.Shared.PortfolioList.title} ({portfolios.length})</span> <A href="/create-portfolio" className="link d-none d-sm-inline">{i18n.Shared.PortfolioList.create}</A></h5>
          </div>
        </div>
      </div>
      <div className="nk-tb-list is-loose traffic-channel-table border-top mt-1">
        <div className="nk-tb-item nk-tb-head">
          <div className="nk-tb-col nk-tb-channel"><span>{i18n.Shared.PortfolioList.name}</span></div>
          <div className="nk-tb-col nk-tb-sessions tb-col-sm"><span>{i18n.Shared.PortfolioList.baseCost}</span></div>
          <div className="nk-tb-col nk-tb-prev-sessions"><span>{i18n.Shared.PortfolioList.cost}</span></div>
          <div className="nk-tb-col nk-tb-change"><span>{i18n.Shared.PortfolioList.profit}</span></div>
          <div className="nk-tb-col nk-tb-change text-right"><span>{i18n.Shared.PortfolioList.actions}</span></div>
          <div className="nk-tb-col nk-tb-trend tb-col-sm text-right"><span>{i18n.Shared.PortfolioList.trend}</span></div>
        </div>
        {portfolios.sort((a, b) => b.currentCost - a.currentCost).map((p, i) => (
          <div className="nk-tb-item" key={p.id}>
            <div className="nk-tb-col nk-tb-channel">
              <span className="tb-lead">
                <A href={`/dashboard?portfolios=${p.id}`}>{p.name}</A>
              </span>
            </div>
            <div className="nk-tb-col nk-tb-sessions tb-col-sm">
              <span className="tb-sub tb-amount"><span>{numberFormat(p.baseCost)}</span></span>
            </div>
            <div className="nk-tb-col nk-tb-prev-sessions">
              <span className="tb-sub tb-amount"><span>{numberFormat(p.currentCost)}</span></span>
            </div>
            <div className="nk-tb-col nk-tb-change">
              <span className="tb-sub"><span>{numberFormat(p.currentProfit)} / {numberFormat(p.currentProfitPercent * 100)}%</span> <span className={`change ${p.currentProfit >= 0 ? 'up' : 'down'}`}><em className={`icon ni ni-arrow-long-${p.currentProfit >= 0 ? 'up' : 'down'}`}></em></span></span>
            </div>
            <ActionButtons
              portfolio={p}
              i18n={i18n}
              removePortfolio={removePortfolio}
              updatePortfolio={updatePortfolio}
            />
            <div className="nk-tb-col nk-tb-trend text-right">
              <div className="traffic-channel-ck ml-auto"><div className="chartjs-size-monitor"><div className="chartjs-size-monitor-expand"><div className=""></div></div><div className="chartjs-size-monitor-shrink"><div className=""></div></div></div>
                <Line
                  className="analytics-line-small chartjs-render-monitor"
                  style={{ display: 'block', width: '130px', height: '44px' }}
                  width={130}
                  height={44}
                  data={{
                    labels: (portfolioCharts.portfolios[p.id] || []).map(() => ''),
                    datasets: [{
                      backgroundColor: 'transparent',
                      borderColor: DEFAULT_COLORS[i],
                      borderWidth: 2,
                      data: (portfolioCharts.portfolios[p.id] || []).map(v => round(v)),
                      label: '',
                      pointBackgroundColor: 'transparent',
                      pointBorderColor: 'transparent',
                      pointBorderWidth: 2,
                      pointHitRadius: 4,
                      pointHoverBackgroundColor: '#fff',
                      pointHoverBorderColor: DEFAULT_COLORS[i],
                      pointHoverBorderWidth: 2,
                      pointHoverRadius: 4,
                      pointRadius: 4,
                      tension: 0.1,
                    }],
                  }}
                  options={{
                    legend: {
                      display: false,
                      labels: {
                        boxWidth: 12,
                        padding: 20,
                        fontColor: '#6783b8',
                      },
                    },
                    maintainAspectRatio: false,
                    tooltips: {
                      enabled: true,
                      backgroundColor: '#eff6ff',
                      titleFontSize: 9,
                      titleFontColor: '#6783b8',
                      titleMarginBottom: 6,
                      bodyFontColor: '#9eaecf',
                      bodyFontSize: 9,
                      bodySpacing: 4,
                      yPadding: 6,
                      xPadding: 6,
                      footerMarginTop: 0,
                      displayColors: false,
                    },
                    scales: {
                      yAxes: [{
                        display: false,
                        ticks: {
                          beginAtZero: false,
                          fontSize: 12,
                          fontColor: '#9eaecf',
                          padding: 0,
                        },
                        gridLines: {
                          color: '#e5ecf8',
                          tickMarkLength: 0,
                          zeroLineColor: '#e5ecf8',
                        },
                      }],
                      xAxes: [{
                        display: false,
                        ticks: {
                          fontSize: 12,
                          fontColor: '#9eaecf',
                          source: 'auto',
                          padding: 0,
                        },
                        gridLines: {
                          color: 'transparent',
                          tickMarkLength: 0,
                          zeroLineColor: '#e5ecf8',
                          offsetGridLines: true,
                        },
                      }],
                    },
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const PortfolioListLoader = () => (
  <div className="card card-bordered">
    <div className="card-inner">
      <Skeleton height={22} className="mb-2" />
      <Skeleton height={16} className="mb-3" />
      <Skeleton height={73} className="mb-3" />
      <Skeleton height={73} className="mb-3" />
      <Skeleton height={73} className="mb-3" />
    </div>
  </div>
)

const mapStateToProps = state => ({
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {}

export const PortfolioList = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PortfolioListComponent)
