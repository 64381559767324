import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { verificationEmail, i18nInit } from '../../actions'

const VerificationComponent = ({
  verificationEmail,
  verifyKey,
  i18nInit,
  i18n,
}) => {
  useEffect(() => {
    i18nInit()
  }, [i18nInit])

  useEffect(() => {
    if (i18n.dictionary.Verify) {
      verificationEmail(verifyKey, i18n.dictionary)
    }
  }, [verificationEmail, i18n, verifyKey])

  return (<div></div>)
}

const mapStateToProps = state => ({
  i18n: state.i18n,
})

const mapDispatchToProps = {
  verificationEmail,
  i18nInit,
}

export const Verification = connect(mapStateToProps, mapDispatchToProps)(VerificationComponent)
