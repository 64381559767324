import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import { A } from 'hookrouter'
import Swal from 'sweetalert2'
import Skeleton from 'react-loading-skeleton'

import { LightChart, LightChartLoader } from '../shared'
import { numberFormat, chartDates } from '../../utils'
import { ASSET_CATEGORIES, CHART_RANGE, CHART_INTERVAL } from '../../constants'

const AssetStockComponent = ({ ticker, i18n, user, wide }) => {
  const [asset, setAsset] = useState(false)
  const [candles, setСandles] = useState(false)

  const [range, setRange] = useState(CHART_RANGE.Y1)
  const [interval, setInterval] = useState(CHART_INTERVAL.ONE_DAY)

  const assetDetilsRequest = useCallback(async () => {
    const { data } = await axios.get(`/assets/${ASSET_CATEGORIES.STOCK}/${ticker}/details`)
    setAsset(data)
  }, [ticker])

  const candlesRequest = useCallback(async () => {
    const { data } = await axios.get(`/assets/${ASSET_CATEGORIES.STOCK}/${ticker}/candles`, {
      params: {
        ...chartDates(range),
        interval,
      },
    })
    setСandles(data)
  }, [range, interval, ticker])

  useEffect(() => {
    assetDetilsRequest()
  }, [assetDetilsRequest])

  useEffect(() => {
    candlesRequest()
  }, [candlesRequest])

  return !asset || !candles ? <AssetLoader wide={wide} /> : (
    <div className={`nk-block mx-auto ${!wide && 'wide-md'}`}>
      <div className="card card-bordered card-full">
        <div className="card-inner">
          <div className="card-title-group align-start">
            <div className="card-title">
              <h6>{asset.longName} ({asset.ticker})</h6>
            </div>
          </div>
          <div className="nk-sale-data">
            <span className="amount">{numberFormat(candles.values[candles.values.length - 1].close)} {asset.currency}</span>
          </div>
        </div>
      </div>
      <LightChart
        customTitle="Flow"
        theme={user.theme}
        i18n={i18n}
        labels={candles.dates.map(d => moment(d).format('YYYY-MM-DD'))}
        data={[{
          label: ticker,
          set: candles.values,
        }]}
        range={range}
        interval={interval}
        onChangeRange={setRange}
        onChangeInterval={setInterval}
      />
      <div className="card card-bordered card-full">
        <div className="card-inner">
          <div className="nk-cov-wg4 mt-2 ml-1">
            <div className="mt-2" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'baseline' }}>
              <h6 className="ml-2">{i18n.AssetStock.aboutcompany}</h6>
              <div className="nk-cov-wg4-meta-tools">
                <A
                  href=""
                  className="btn btn-outline-none"
                  onClick={() => Swal.fire({
                    title: i18n.AssetStock.aboutcompany,
                    text: asset.description,
                    icon: 'info',
                  })}
                >
                  <em className="icon ni ni-info"></em>
                </A>
              </div>
            </div>
          </div>
          <div className="nk-cov-wg4 mt-2" style={{ borderTop: '1px solid #dbdfea' }}>
            <div className="row">
              <div className="col-lg-4 col-xs-12">
                <table className="table table-borderless mt-1">
                  <tbody>
                    <tr>
                      <th>{i18n.AssetStock.country}</th>
                      <td>{asset.country}</td>
                    </tr>
                    <tr>
                      <th>{i18n.AssetStock.market}</th>
                      <td>{asset.exchange}</td>
                    </tr>
                    <tr>
                      <th>{i18n.AssetStock.type}</th>
                      <td>{asset.quoteType}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-4 col-xs-12">
                <table className="table table-borderless mt-1">
                  <tbody>
                    <tr>
                      <th>{i18n.AssetStock.city}</th>
                      <td>{asset.city}</td>
                    </tr>
                    <tr>
                      <th>{i18n.AssetStock.industry}</th>
                      <td>{asset.industry}</td>
                    </tr>
                    <tr>
                      <th>{i18n.AssetStock.website}</th>
                      <td><a href={asset.website} target="_blank" rel="noopener noreferrer">{asset.website}</a></td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-4 col-xs-12">
                <table className="table table-borderless mt-1">
                  <tbody>
                    <tr>
                      <th>{i18n.AssetStock.address1}</th>
                      <td>{asset.address1}</td>
                    </tr>
                    <tr>
                      <th>{i18n.AssetStock.sector}</th>
                      <td>{asset.sector}</td>
                    </tr>
                    <tr>
                      <th>{i18n.AssetStock.zip}</th>
                      <td>{asset.zip}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const AssetLoader = ({ wide }) => (
  <div className={`nk-block mx-auto ${!wide && 'wide-md'}`}>
    <div className="card card-bordered card-full">
      <div className="card-inner">
        <Skeleton height={43} className="mb-2" />
      </div>
    </div>
    <LightChartLoader />
    <div className="card card-bordered card-full">
      <div className="card-inner">
        <div className="nk-cov-wg4 mt-2 ml-1">
          <Skeleton height={36} />
        </div>
        <div className="nk-cov-wg4 mt-2" style={{ borderTop: '1px solid #dbdfea' }}>
          <Skeleton height={30} className="mt-2" />
          <Skeleton height={30} className="mt-2" />
          <Skeleton height={30} className="mt-2 mb-2" />
        </div>
      </div>
    </div>
  </div>
)

const mapStateToProps = state => ({
  i18n: state.i18n.dictionary,
  user: state.user,
})

const mapDispatchToProps = {}

export const AssetStock = connect(mapStateToProps, mapDispatchToProps)(AssetStockComponent)