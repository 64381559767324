import React, { useState } from 'react'
import { connect } from 'react-redux'
import Swal from 'sweetalert2'

import { updateUser } from '../../actions'
import { SettingsLayout } from './layout'
import { CURRENCIES, LANGUAGES } from '../../constants'

const PersonalSettingsComponent = ({ user, updateUser, i18n }) => {
  const [currencies, setCurrencies] = useState(user.user.currencies)
  const [language, setLanguage] = useState(user.user.language)

  const onClickChangeCurrencies = () => Swal.fire({
    title: i18n.PersonalSettings.updateP,
    html: `
      <div className="form-group">
        <label className="form-label" for="currency">${i18n.PersonalSettings.main}</label>
        <div className="form-control-wrap">
          <div className="form-control-select">
            <select className="form-control" id="first-currency" value="${currencies[0]}" data-ui="sm" tabindex="-1" aria-hidden="true">
              ${CURRENCIES.map(c => `
                <option value="${c}" ${currencies[0] === c && 'selected'}>${c}</option>
              `)}
            </select>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label" for="currency">${i18n.PersonalSettings.additional}</label>
        <div className="form-control-wrap">
          <div className="form-control-select">
            <select className="form-control" id="second-currency" value="${currencies[1]}" data-ui="sm" tabindex="-1" aria-hidden="true">
              ${CURRENCIES.map(c => `
                <option value="${c}" ${currencies[1] === c && 'selected'}>${c}</option>
              `)}
            </select>
          </div>
        </div>
      </div>
      <div className="form-group">
        <label className="form-label" for="currency">${i18n.PersonalSettings.additional}</label>
        <div className="form-control-wrap">
          <div className="form-control-select">
            <select className="form-control" id="third-currency" value="${currencies[2]}" data-ui="sm" tabindex="-1" aria-hidden="true">
              ${CURRENCIES.map(c => `
                <option value="${c}" ${currencies[2] === c && 'selected'}>${c}</option>
              `)}
            </select>
          </div>
        </div>
      </div>
    `,
    preConfirm: () => [
      document.getElementById('first-currency').value,
      document.getElementById('second-currency').value,
      document.getElementById('third-currency').value,
    ],
  }).then(result => {
    if (result.isConfirmed) {
      setCurrencies(result.value)
      save({ currencies: result.value })
    }
  })

  const onClickChangeLanguage = () => Swal.fire({
    title: i18n.PersonalSettings.updateP,
    html: `
      <div className="form-group">
        <label className="form-label" for="currency">${i18n.PersonalSettings.language}</label>
        <div className="form-control-wrap">
          <div className="form-control-select">
            <select className="form-control" id="user-language" data-ui="sm" tabindex="-1" aria-hidden="true">
              ${LANGUAGES.map(l => `
                <option value=${l} ${language === l && 'selected'}>${l.toUpperCase()}</option>
              `)}
            </select>
          </div>
        </div>
      </div>
    `,
    preConfirm: () => document.getElementById('user-language').value,
  }).then(result => {
    if (result.isConfirmed) {
      setLanguage(result.value)
      save({ language: result.value })
    }
  })

  const save = data => updateUser({
    id: user.user.id,
    email: user.user.email,
    currencies,
    language,
    ...data,
  })

  return (
    <SettingsLayout>
      <div className="card-inner card-inner-lg">
        <div className="nk-block-head nk-block-head-lg">
          <div className="nk-block-between">
            <div className="nk-block-head-content">
              <h4 className="nk-block-title">{i18n.PersonalSettings.title}</h4>
              <div className="nk-block-des">
                <p>{i18n.PersonalSettings.basic}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="nk-block">
          <div className="nk-data data-list">
            <div className="data-head">
              <h6 className="overline-title">{i18n.PersonalSettings.basics}</h6>
            </div>
            <div className="data-item" onClick={onClickChangeCurrencies}>
              <div className="data-col">
                <span className="data-label">{i18n.PersonalSettings.currencies}</span>
                <span className="data-value">{currencies.join(', ')}</span>
              </div>
              <div className="data-col data-col-end"><span className="data-more"><em className="icon ni ni-forward-ios"></em></span></div></div>
            <div className="data-item" onClick={onClickChangeLanguage}>
              <div className="data-col">
                <span className="data-label">{i18n.PersonalSettings.language}</span>
                <span className="data-value">{language.toUpperCase()}</span>
              </div>
              <div className="data-col data-col-end"><span className="data-more"><em className="icon ni ni-forward-ios"></em></span></div>
            </div>
          </div>
        </div>
      </div>
    </SettingsLayout>
  )
}

const mapStateToProps = state => ({
  user: state.user,
  i18n: state.i18n.dictionary,
})

const mapStateToDispatch = {
  updateUser,
}

export const PersonalSettings = connect(
  mapStateToProps,
  mapStateToDispatch,
)(PersonalSettingsComponent)
