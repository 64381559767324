import { enGB } from 'date-fns/esm/locale'

export const EN = {
  'AssetStock': {
    'type': 'Type:',
    'market': 'Market:',
    'currency': 'Currency:',
    'country': 'Country:',
    'aboutcompany': 'About company',
    'address1': 'Address:',
    'city': 'City:',
    'website': 'Website:',
    'industry': 'Industry:',
    'sector': 'Sector:',
    'zip': 'Zip:',
    'updatedAt': 'Updated:',
    'marketStatus': 'Market status:',
    'open': 'open',
    'close': 'closed',
  },
  'CreatePortfolio': {
    'title': 'Create Portfolio',
    'name': 'Portfolio Name',
    'save': 'Save',
  },
  'CreateTransaction': {
    'modalText': 'At first, you should create a portfolio!',
    'modalButton': 'Create',
    'title': 'Create transaction',
    'ticker': 'Ticker or campany name',
    'date': 'Date',
    'time': 'Time',
    'timePlaceholder': 'HH:MM',
    'category': 'Choose category',
    'stock': 'Stock',
    'currency': 'Cash',
    'other': 'Other',
    'type': 'Choose type',
    'buy': 'Buy',
    'sell': 'Sell',
    'dividend': 'Dividend',
    'tax': 'Tax',
    'portfolio': 'Choose portfolio',
    'count': 'Count',
    'price': 'Price',
    'commission': 'Commission',
    'percent': 'Is Percent?',
    'save': 'Save',
    'addCashTransaction': 'Add cash transaction',
    'add': 'Add',
  },
  'Dashboard': {
    'categoryRatio': 'Category Ratio',
    'tickerRatio': 'Ticker Ratio',
    'stock': 'Stock',
    'etf': 'ETF',
    'currency': 'Cash',
    'cryptocurrency': 'Crypto',
    'other': 'Other',
  },
  'Home': {
    'portfoliosRatio': 'Portfolios Ratio',
  },
  'DeletePortfolio': {
    'title': 'Delete Portfolio',
    'delete': 'Delete',
    'cancel': 'Cancel',
  },
  'DeleteTransaction': {
    'title': 'Delete Transaction',
    'delete': 'Delete',
    'cancel': 'Cancel',
  },
  'Feedback': {
    'title': 'What kind of feedback do you have about this tool?',
    'report': 'Report an issue',
    'share': 'Share an idea',
    'give': 'Give a compliment',
    'tell': 'Tell us about the issue',
    'placeholder': 'Enter feedback for the administration here here.',
    'send': 'Send',
  },
  'MobileFooter': {
    'feedback': 'Feedback',
    'about': 'About Data',
    'copyright': 'Copyright 2024',
    'company': 'Reagle Investment',
  },
  'Sidebar': {
    'home': 'Home',
    'dashboard': 'Dashboard',
    'portfolios': 'Portfolios',
    'transactions': 'Transactions',
    'settings': 'Settings',
    'feedback': 'Feedback',
    'logout': 'Logout',
  },
  'Login': {
    'signIn': 'Sign-In',
    'access': 'Access the Reagle using your email and password.',
    'email': 'Email',
    'emailPlaceholder': 'Enter your email address',
    'password': 'Password',
    'forgot': 'Forgot Code?',
    'passwordPlaceholder': 'Enter your password',
    'new': 'New on our platform?',
    'create': 'Create an account',
    'copy': '2024 Reagle. All Rights Reserved.',
  },
  'NotFound': {
    'title': 'Oops! Why you’re here?',
    'massage': 'We are very sorry for inconvenience. It looks like you’re try to access a page that either has been deleted or never existed.',
  },
  'Portfolio': {
    'categoryRatio': 'Category Ratio',
    'tickerRatio': 'Ticker Ratio',
    'stock': 'Stock',
    'etf': 'ETF',
    'currency': 'Cash',
    'cryptocurrency': 'Crypto',
    'other': 'Other',
  },
  'Registration': {
    'title': 'Register',
    'make': 'Make your Investments more Productive with Reagle.',
    'email': 'Email',
    'emailPlaceholder': 'Enter your email address',
    'password': 'Password',
    'passwordPlaceholder': 'Enter your password',
    'currencies': 'Currencies',
    'language': 'Language',
    'already': 'Already have an account?',
    'signIn': 'Sign in instead',
    'copy': ' 2024 Reagle. All Rights Reserved.',
  },
  'ResetPasswordInit': {
    'modalTitleSuccess': 'Thank you for submitting form',
    'modalTitleSuccessText': 'You can now check your email address.',
    'modalTitleError': 'Error',
    'modalTitleTextError': 'It looks like that email doesn’t exist or incorrect entered',
    'title': 'Reset password',
    'titleText': 'If you forgot your password, well, then we’ll email you instructions to reset your password.',
    'email': 'Email',
    'emailPlaceholder': 'Enter your email address',
    'send': 'Send Reset Link',
    'return': 'Return to login',
    'copy': ' 2024 Reagle. All Rights Reserved.',
  },
  'ResetPasswordFinish': {
    'modalTitleError': 'Error',
    'modalTitleTextError': 'Sorry, not valid link, try reset password again',
    'title': 'Reset password',
    'titleText': 'Enter a new password that differs from the old FFFIIIIIIIIX',
    'passwordPlaceholder': 'Enter your new password',
    'send': 'Change',
    'copy': ' 2024 Reagle. All Rights Reserved.',
    'reAuthenticationTextSuccess': 'We sent you an email to activate your account by email',
    'reAuthenticationTextError': 'Something went wrong please try again later',
  },
  'Search': {
    'searchPlaceholder': 'Start typing ticker or name',
    'title': 'Screener',
    'stock': 'Stock',
    'etf': 'ETF',
    'currency': 'Cash',
    'cryptocurrency': 'Crypto',
    'ticker': 'Ticker',
    'name': 'Name',
    'country': 'Country',
    'price': 'Price',
  },
  'SettingsLayout': {
    'general': 'General Information',
    'security': 'Security Settings',
  },
  'PersonalSettings': {
    'title': 'General Information',
    'basic': 'Basic preferences, like your currencies and language, that you use on platform.',
    'basics': 'Basics',
    'currencies': 'Currencies',
    'language': 'Language',
    'updateP': 'Update Profile',
    'main': 'Main currency',
    'additional': 'Additional currency',
    'update': 'Update',
    'cancel': 'Cancel',
  },
  'SecuritySettings': {
    'title': 'Security Settings',
    'these': 'These settings are helps you keep your account secure.',
    'password': 'Password',
    'set': 'Set a unique password to protect your account.',
    'email': ' Email',
    'address': 'The email address for working with Reagle.',
    'change': 'Change',
    'changePassword': 'Change Password',
    'changeEmail': 'Change Email',
    'placeholderPassword': 'Enter new password here...',
    'placeholderEmail': 'Enter new email adress here...',
    'authentication': 'Authentication',
    'after': 'After activation, you can use our platform without restrictions',
    'activate': 'Activate',
    'modalActivateTittle': 'Send a reconfirmation email address?',
    'modalActivateSend': 'Send',
    'modalActivateCencel': 'Cencel',
  },
  'Shared': {
    'Assets': {
      'assets': 'Assets',
      'all': 'All',
      'stock': 'Stock',
      'etf': 'ETF',
      'currency': 'Cash',
      'cryptocurrency': 'Crypto',
      'other': 'Other',
      'type': 'Type',
      'ticker': 'Ticker',
      'portfolioPercent': 'Portfolio Percent',
      'count': 'Count',
      'cost': 'Cost per share',
      'purchaseCost': 'Purchase Cost',
      'sum': 'Sum Cost',
      'profit': 'Profit',
    },
    'CapitalFlow': {
      'title': 'Capital Flow',
      'total': 'Total',
    },
    'PortfolioList': {
      'modalDeleteTitle': 'Are you sure?',
      'modalDeleteText': 'You won\'t be able to revert this!',
      'modalDeleteConfirm': 'Yes, delete it!',
      'modalDeleteCancel': 'No, cancel!',
      'modalUpdateEdit': 'Edit portfolio',
      'modalUpdate': 'Update',
      'title': 'Portfolios',
      'create': 'Create a New',
      'name': 'Name',
      'baseCost': 'Base Cost',
      'cost': 'Cost',
      'profit': 'Profit',
      'actions': 'Actions',
      'trend': 'Trend',
      'edit': 'Edit',
      'remove': 'Remove',
    },
    'TotalValue': {
      'title': 'Total Value',
      'overview': 'Overview',
      'currentProfit': 'Current Profit',
      'current': 'Current',
      'allTimeProfit': 'All Time Profit',
      'allTime': 'All Time',
      'baseCostAndPercent': 'Base Cost | ATP / Base Cost',
      'commissionAndDividends': 'Commission & Tax | Dividends',
    },
    'TransactionList': {
      'modalDeleteTitle': 'Are you sure?',
      'modalDeleteText': 'You won\'t be able to revert this!',
      'modalDeleteConfirm': 'Yes, delete it!',
      'modalDeleteCancel': 'No, cancel!',
      'title': 'Transactions',
      'createNew': 'Create a New',
      'all': 'All',
      'buy': 'Buy',
      'sell': 'Sell',
      'dividend': 'Dividend',
      'tax': 'Tax',
      'stock': 'Shares',
      'currency': 'Units',
      'other': 'Units',
      'type': 'Type',
      'asset': 'Asset',
      'date': 'Date',
      'price': 'Price',
      'count': 'Count',
      'commission': 'Commission',
      'cost': 'Cost',
      'editTransaction': 'Edit',
      'removeTransaction': 'Remove',
    },
  },
  'UpdatePortfolio': {
    'title': 'Update Portfolio',
    'portfolioName': 'Portfolio Name',
    'update': 'Update',
  },
  'UpdateTransaction': {
    'title': 'Update transaction',
    'ticker': 'Ticker or campany name',
    'date': 'Date',
    'time': 'Time',
    'timePlaceholder': 'HH:MM',
    'category': 'Choose category',
    'stock': 'Stock',
    'etf': 'ETF',
    'currency': 'Cash',
    'cryptocurrency': 'Crypto',
    'other': 'Other',
    'type': 'Choose type',
    'buy': 'Buy',
    'sell': 'Sell',
    'dividend': 'Dividend',
    'tax': 'Tax',
    'portfolio': 'Choose portfolio',
    'count': 'Count',
    'price': 'Price',
    'commission': 'Commission in numerical format',
    'percent': 'Is Percent?',
    'update': 'Update',
    'cashTransactionText': 'If you used an automatic cash operation when creating a transaction (add/withdrawal), then you need to edit this transaction manually, if it is necessary',
    datePickerLocale: enGB,
    datePickerFormat: 'MMMM d, yyyy h:mm aa',
  },
  'Verify': {
    'modalTitleSuccess': 'Success',
    'modalTitleError': 'Error',
    'html': 'I will redirect you Reagle',
    'text': 'Something gone wrong, please try again',
  },
  'Watchlist': {
    'title': 'Watchlist',
    'addAsset': 'Add Asset',
    'type': 'Category',
    'ticker': 'Ticker',
    'select': 'Select',
    'portfolioAssetsTitle': 'Portfolio Assets',
    'modalCreateTitle': 'Add element to watchlist',
    'modalCreateConfirm': 'Add',
    'modalCreateCancel': 'Cancel',
    'modalDeleteTitle': 'Remove element from watchlist',
    'modalDeleteConfirm': 'Remove',
    'modalDeleteCancel': 'Cancel',
  },
}
