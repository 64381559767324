import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { A, navigate } from 'hookrouter'
import axios from 'axios'
import Select from 'react-select'
import Swal from 'sweetalert2'
import DatePicker from 'react-datepicker'

import { DropdownMenu } from '../shared'
import { ASSET_CATEGORIES, TRANSACTION_TYPES, MIN_DATE } from '../../constants'
import { selectStylesHandler, selectThemeHandler } from '../../utils'

const CreateTransactionComponent = ({ user, i18n }) => {
  const [category, setCategory] = useState(ASSET_CATEGORIES.STOCK)
  const [type, setType] = useState(TRANSACTION_TYPES.BUY)
  const [date, setDate] = useState(new Date())
  const [transactionPortfolio, setPortfolio] = useState('')
  const [count, setCount] = useState('')
  const [price, setPrice] = useState('')
  const [commission, setCommission] = useState('0')
  const [currency, setCurrency] = useState(user.currentCurrency)
  const [responses, setResponses] = useState([])

  const [portfolios, setPortfolios] = useState([])

  const [search, setSearch] = useState('')
  const [ticker, setTicker] = useState('')
  const [percent, setPercent] = useState(false)

  const [cashTransaction, setCashTransaction] = useState(false)

  const portfoliosRequest = useCallback(async () => {
    const { data } = await axios.get('/portfolio')
    if (data.length) {
      setPortfolios(data)
    } else {
      await Swal.fire({
        icon: 'info',
        text: i18n.CreateTransaction.modalText,
        confirmButtonText: i18n.CreateTransaction.modalButton,
      })
      navigate('/create-portfolio')
    }
  }, [i18n])

  const searchListRequest = useCallback(async () => {
    const { data } = await axios.get('/assets/search', { params: { category, query: search } })
    const newResponses = (data || []).map(r => ({
      value: r.ticker,
      label: `${r.ticker} - ${r.shortName}`,
    }))
    if (ASSET_CATEGORIES.OTHER === category) {
      newResponses.unshift({ value: search, label: search })
    }
    setResponses(newResponses)
  }, [search, category])

  useEffect(() => {
    if ([TRANSACTION_TYPES.DIVIDEND, TRANSACTION_TYPES.TAX].includes(type)) {
      setCount(1)
      if (TRANSACTION_TYPES.TAX === type) {
        setTicker({ value: i18n.CreateTransaction.tax, label: i18n.CreateTransaction.tax })
      }
    }
  }, [i18n, type])

  useEffect(() => {
    portfoliosRequest()
  }, [portfoliosRequest])

  useEffect(() => {
    if (portfolios.length) {
      setPortfolio(portfolios[0].id)
    }
  }, [portfolios])

  useEffect(() => {
    searchListRequest()
  }, [searchListRequest])

  const onSave = async e => {
    e.preventDefault()
    const transactionCommission = percent
      ? +count * +price * +commission / 100
      : +commission
    const { data } = await axios.post('/transaction', {
      ticker: ticker.value,
      date: new Date(date),
      category,
      type: type,
      count: +count,
      price: +price,
      commission: transactionCommission || 0,
      currency,
      portfolio: transactionPortfolio,
    })
    if (cashTransaction && [TRANSACTION_TYPES.BUY, TRANSACTION_TYPES.SELL].includes(type)) {
      await axios.post('/transaction', {
        ticker: currency,
        date: new Date(date),
        category: ASSET_CATEGORIES.CURRENCY,
        type: type === TRANSACTION_TYPES.BUY ? TRANSACTION_TYPES.SELL : TRANSACTION_TYPES.BUY,
        count: +price * +count,
        price: 1,
        commission: 0,
        currency,
        portfolio: transactionPortfolio,
      })
    }
    navigate(`/dashboard?portfolios=${data.portfolio}`)
  }

  const selectStyles = selectStylesHandler(user.theme === 'dark')
  const selectTheme = selectThemeHandler()

  return !portfolios || !portfolios.length ? <div ></div> : (
    <div className="content-page wide-xs m-auto" >
      <div className="nk-block">
        <div className="card card-bordered">
          <div className="card-aside-sm">
            <div className="card-content wide-xs m-auto">
              <ul className="nav nav-tabs nav-tabs-mb-icon nav-tabs-card">
                <li className="nav-item">
                  <A
                    className={`nav-link ${category === ASSET_CATEGORIES.STOCK && 'active'}`}
                    href=""
                    onClick={() => setCategory(ASSET_CATEGORIES.STOCK)}
                  >
                    <em className="icon ni ni-globe"></em>
                    <span>Stock</span>
                  </A>
                </li>
                <li className="nav-item">
                  <A
                    className={`nav-link ${category === ASSET_CATEGORIES.ETF && 'active'}`}
                    href=""
                    onClick={() => setCategory(ASSET_CATEGORIES.ETF)}
                  >
                    <em className="icon ni ni-row-mix"></em>
                    <span>ETF</span>
                  </A>
                </li>
                <li className="nav-item">
                  <A
                    className={`nav-link ${category === ASSET_CATEGORIES.CURRENCY && 'active'}`}
                    href=""
                    onClick={() => setCategory(ASSET_CATEGORIES.CURRENCY)}
                  >
                    <em className="icon ni ni-money"></em>
                    <span>Cash</span>
                  </A>
                </li>
                <li className="nav-item">
                  <A
                    className={`nav-link ${category === ASSET_CATEGORIES.CRYPTOCURRENCY && 'active'}`}
                    href=""
                    onClick={() => setCategory(ASSET_CATEGORIES.CRYPTOCURRENCY)}
                  >
                    <em className="icon ni ni-coin-alt"></em>
                    <span>Crypto</span>
                  </A>
                </li>
                <li className="nav-item">
                  <A
                    className={`nav-link ${category === ASSET_CATEGORIES.OTHER && 'active'}`}
                    href=""
                    onClick={() => setCategory(ASSET_CATEGORIES.OTHER)}
                  >
                    <em className="icon ni ni-notes-alt"></em>
                    <span>Other</span>
                  </A>
                </li>
              </ul>
              <div className="card-inner" style={{ justifyContent: 'center' }}>
                <div className="nk-block-head">
                  <h5 className="title text-center">{i18n.CreateTransaction.title}</h5>
                </div>
                <form onSubmit={onSave} className="buysell-field form-group">
                  <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.CreateTransaction.ticker}</label>
                    </div>
                    <Select
                      value={ticker}
                      onInputChange={v => setSearch(v)}
                      onChange={v => setTicker(v)}
                      options={responses}
                      placeholder=""
                      isClearable={true}
                      isSearchable={true}
                      isFocused={true}
                      styles={selectStyles}
                      theme={selectTheme}
                    />
                  </div>
                  <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.CreateTransaction.date}</label>
                    </div>
                    <div className="form-control-wrap">
                      <div className="form-icon form-icon-right">
                        <em className="icon ni ni-calendar-alt"></em>
                      </div>
                      <DatePicker
                        selected={date}
                        onChange={date => setDate(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        showTimeInput
                        minDate={MIN_DATE}
                        maxDate={new Date()}
                        locale={i18n.UpdateTransaction.datePickerLocale}
                        dateFormat={i18n.UpdateTransaction.datePickerFormat}
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.CreateTransaction.type}</label>
                    </div>
                    <Select
                      defaultValue={{
                        value: TRANSACTION_TYPES.BUY,
                        label: i18n.CreateTransaction.buy,
                      }}
                      options={[
                        {
                          value: TRANSACTION_TYPES.BUY,
                          label: i18n.CreateTransaction.buy,
                        },
                        {
                          value: TRANSACTION_TYPES.SELL,
                          label: i18n.CreateTransaction.sell,
                        },
                        {
                          value: TRANSACTION_TYPES.DIVIDEND,
                          label: i18n.CreateTransaction.dividend,
                        },
                        {
                          value: TRANSACTION_TYPES.TAX,
                          label: i18n.CreateTransaction.tax,
                        },
                      ]}
                      onChange={(options) => setType(options.value)}
                      isSearchable={false}
                      isFocused={true}
                      styles={selectStyles}
                      theme={selectTheme}
                    />
                  </div>
                  <div className="form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.CreateTransaction.portfolio}</label>
                    </div>
                    <Select
                      defaultValue={{ label: portfolios[0].name, value: portfolios[0].id }}
                      options={portfolios.map(p => ({ value: p.id, label: p.name }))}
                      onChange={(options) => setPortfolio(options.value)}
                      isSearchable={false}
                      isFocused={true}
                      styles={selectStyles}
                      theme={selectTheme}
                    />
                  </div>
                  <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.CreateTransaction.count}</label>
                    </div>
                    <div className="form-control-group">
                      <input
                        style={{ fontSize: '13px', padding: '20px', height: '0' }}
                        type="text"
                        className="form-control form-control-lg form-control-number"
                        id="buysell-amount"
                        name="bs-amount"
                        value={count}
                        onChange={e => setCount(e.target.value)}
                        pattern="\d+(.\d{0,})?"
                        required
                      />
                    </div>
                  </div>
                  <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.CreateTransaction.price}</label>
                    </div>
                    <div className="form-control-group">
                      <input
                        style={{ fontSize: '13px', padding: '20px', height: '0' }}
                        type="text"
                        className="form-control form-control-lg form-control-number"
                        id="buysell-amount"
                        name="bs-amount"
                        value={price}
                        onChange={e => setPrice(e.target.value)}
                        pattern="\d+(.\d{0,})?"
                      />
                      <div className="form-dropdown">
                        <div className="dropdown">
                          <DropdownMenu
                            btn={<A href="" className="dropdown-indicator-caret">{currency}</A>}
                            content={
                              <div className="dropdown-menu dropdown-menu-xxs dropdown-menu-right text-center show">
                                <ul className="link-list-plain">
                                  {user.user.currencies.map(c => (
                                    <li key={c}><A href="" onClick={() => setCurrency(c)}>{c}</A></li>
                                  ))}
                                </ul>
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginBottom: '0.4rem' }} className="buysell-field form-group">
                    <div className="form-label-group">
                      <label className="form-label" htmlFor="buysell-amount">{i18n.CreateTransaction.commission}</label>
                    </div>
                    <div className="form-control-group">
                      <input
                        style={{ fontSize: '13px', padding: '20px', height: '0' }}
                        type="text"
                        className="form-control form-control-lg form-control-number"
                        id="buysell-amount"
                        name="bs-amount"
                        value={commission}
                        onChange={e => setCommission(e.target.value)}
                        pattern="\d+(.\d{0,})?"
                      />
                      <div className="form-dropdown">
                        <div className="custom-control custom-switch">
                          <input onClick={() => setPercent(!percent)} type="checkbox" className="custom-control-input" id="customSwitch6" />
                          <label className="custom-control-label" htmlFor="customSwitch6" style={{ color: 'rgb(84, 198, 167)' }}>{i18n.CreateTransaction.percent}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {[TRANSACTION_TYPES.BUY, TRANSACTION_TYPES.SELL].includes(type) ? (
                    <div className="form-group">
                      <label className="form-label">{i18n.CreateTransaction.addCashTransaction}</label>
                      <ul className="custom-control-group g-3 align-center">
                        <li>
                          <div className="custom-control custom-checkbox">
                            <input onClick={() => setCashTransaction(!cashTransaction)} type="checkbox" className="custom-control-input" id="com-email-1" />
                            <label className="custom-control-label" htmlFor="com-email-1">{i18n.CreateTransaction.add}</label>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ) : ''}
                  <div style={{ marginLeft: 'auto', marginTop: '25px', marginRight: 'auto', width: '34%' }} className="form-control-group">
                    <button style={{ fontSize: '13px' }} className="btn btn-lg btn-block btn-primary" data-toggle="modal"
                      data-target="#buy-coin">{i18n.CreateTransaction.save}</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

const mapStateToProps = state => ({
  transaction: state.transaction,
  portfolio: state.portfolio,
  user: state.user,
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {}

export const CreateTransaction = connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateTransactionComponent)
