import React from 'react'
import { A } from 'hookrouter'
import { connect } from 'react-redux'

export const MobileFooterComponent = ({ i18n }) => (
  <div className="nk-footer d-md-none">
    <div className="container-fluid">
      <div className="nk-footer-wrap gy-1 gx-4">
        {/* <div className="nk-footer-links">
          <ul className="nav nav-sm">
            <li className="nav-item"><A className="nav-link" href="/" data-toggle="modal" data-target="#covid-feedback">{i18n.MobileFooter.feedback}</A></li>
            <li className="nav-item"><A className="nav-link" href="/" data-toggle="modal" data-target="#covid-about">{i18n.MobileFooter.about}</A></li>
          </ul>
        </div> */}
        <div className="nk-footer-copyright"> &copy; {i18n.MobileFooter.copyright}, <A href="/" target="_blank">{i18n.MobileFooter.company}</A>
        </div>
      </div>
    </div>
  </div>
)

const mapStateToProps = state => ({
  i18n: state.i18n.dictionary,
})

const mapDispatchToProps = {}

export const MobileFooter = connect(mapStateToProps, mapDispatchToProps)(MobileFooterComponent)